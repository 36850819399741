// Core
import { ComponentType, CSSProperties, FormHTMLAttributes, ReactNode, Ref } from 'react';
// Packages
import { FormProps } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { Form as AntForm, FormInstance } from 'antd';

export type TFormInstance = FormInstance;

interface IFormProps extends FormHTMLAttributes<HTMLFormElement> {
  autoComplete?: 'on' | 'off';
  children?: ReactNode;
  className?: string;
  colon?: boolean;
  component?: ComponentType | false;
  fields?: FormProps['fields'];
  form?: FormInstance;
  initialValues?: Store;
  labelAlign?: 'left' | 'right';
  labelCol?: object;
  layout?: 'horizontal' | 'vertical' | 'inline';
  name?: string;
  preserve?: boolean;
  ref?: Ref<FormInstance<any>>;
  requiredMark?: boolean | 'optional';
  scrollToFirstError?: boolean;
  size?: 'small' | 'middle' | 'large';
  style?: CSSProperties;
  validateMessages?: FormProps['validateMessages'];
  validateTrigger?: string | string[];
  wrapperCol?: object;
  onFinish?: (values: any) => void;
  onFinishFailed?: FormProps['onFinishFailed'];
  onValuesChange?: (changedValues: any, values: any) => void;
  onFieldsChange?: FormProps['onFieldsChange'];
}

/**
 * High performance Form component with data scope management. Including data collection, verification, and styles.
 *
 * @description When you need to create an instance or collect information. When you need to validate fields in certain rules
 *
 * @param autoComplete - Some ReactNode
 * @param children - Some ReactNode
 * @param className - The picker className
 * @param colon - Configure the default value of colon for Form.Item. Indicates whether the colon after the label is displayed (only effective when prop layout is horizontal)
 * @param component - Set the Form rendering element. Do not create a DOM node for false
 * @param fields - Control of form fields through state management (such as redux). Not recommended for non-strong demand. View example
 * @param form - Form control instance created by Form.useForm(). Automatically created when not provided
 * @param initialValues - Set value by Form initialization or reset
 * @param labelAlign - The text align of label of all items
 * @param labelCol - Label layout, like <Col> component. Set span offset value like {span: 3, offset: 12} or sm: {span: 3, offset: 12}
 * @param layout - Form layout
 * @param name - Form name. Will be the prefix of Field id
 * @param preserve - Keep field value even when field removed
 * @param requiredMark - Required mark style. Can use required mark or optional mark. You can not config to single Form.Item since this is a Form level config
 * @param scrollToFirstError - Auto scroll to first failed field when submit
 * @param size - Set field component size (antd components only)
 * @param style - To customize the styles
 * @param validateMessages - Validation prompt template, description see below
 * @param validateTrigger - Config field validate trigger
 * @param wrapperCol - The layout for input controls, same as labelCol
 * @param onFieldsChange - Trigger when field updated
 * @param onFinish - Trigger after submitting the form and verifying data successfully
 * @param onFinishFailed - Trigger after submitting the form and verifying data failed
 * @param onValuesChange - Trigger when value updated
 */

const Form = ({
  autoComplete = 'off',
  children,
  className,
  colon = true,
  component,
  fields,
  form,
  initialValues,
  labelAlign = 'right',
  labelCol,
  layout = 'horizontal',
  name,
  preserve = true,
  ref,
  requiredMark = true,
  scrollToFirstError = false,
  size = 'middle',
  style,
  validateMessages,
  validateTrigger,
  wrapperCol,
  onFieldsChange,
  onFinish,
  onFinishFailed,
  onValuesChange,
  ...props
}: IFormProps) => (
  <AntForm
    autoComplete={autoComplete}
    className={className}
    colon={colon}
    component={component}
    fields={fields}
    form={form}
    initialValues={initialValues}
    labelAlign={labelAlign}
    labelCol={labelCol}
    layout={layout}
    name={name}
    preserve={preserve}
    ref={ref}
    requiredMark={requiredMark}
    scrollToFirstError={scrollToFirstError}
    size={size}
    style={style}
    validateMessages={validateMessages}
    validateTrigger={validateTrigger}
    wrapperCol={wrapperCol}
    onFieldsChange={onFieldsChange}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    onValuesChange={onValuesChange}
    {...props}
  >
    {children}
  </AntForm>
);

Form.ErrorList = AntForm.ErrorList;
Form.useForm = AntForm.useForm;
Form.Provider = AntForm.Provider;

export default Form;
