// Core
import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
// Packages
import { Space as AntSpace } from 'antd';

type SpaceSize = 'small' | 'middle' | 'large' | number;

interface ISpaceProps extends HTMLAttributes<HTMLDivElement> {
  align?: 'start' | 'end' | 'center' | 'baseline';
  direction?: 'vertical' | 'horizontal';
  size?: SpaceSize | [SpaceSize, SpaceSize];
  split?: ReactNode;
  wrap?: boolean;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Set components spacing.
 *
 * @description Avoid components clinging together and set a unified space
 *
 * @param align - Align items
 * @param direction - The space direction
 * @param size - The space size
 * @param split - Set split
 * @param wrap - 	Auto wrap line, when "horizontal" effective
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Space = ({
  align,
  direction = 'horizontal',
  size = 'small',
  split,
  wrap = false,
  className,
  style,
  children,
  ...props
}: ISpaceProps) => (
  <AntSpace
    align={align}
    direction={direction}
    size={size}
    split={split}
    wrap={wrap}
    className={className}
    style={style}
    {...props}
  >
    {children}
  </AntSpace>
);

export default Space;
