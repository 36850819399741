// Modules
import xhr from 'modules/xhr';
// Interfaces and types
import { IMessageRes } from 'types/appTypes';
import {
  IGetChangeLogsRes,
  ICreateChangeLogBody,
  IGetChangeLogDetails,
  IUpdateChangeLogBodyWithParam,
} from 'types/changeLogTypes';

export const getChangeLogs = () => {
  return xhr.get<IGetChangeLogsRes, IGetChangeLogsRes>('/admin/change-logs');
};

export const createChangeLog = (body: ICreateChangeLogBody) => {
  return xhr.post<IMessageRes, IMessageRes>('/admin/change-logs', body);
};

export const updateChangeLog = ({ id, body }: IUpdateChangeLogBodyWithParam) => {
  return xhr.put<IMessageRes, IMessageRes>(`/admin/change-logs/${id}`, body);
};

export const getChangeLogDetails = (id: number) => {
  return xhr.get<IGetChangeLogDetails, IGetChangeLogDetails>(`/admin/change-logs/${id}`);
};

export const deleteChangeLog = (id: number) => {
  return xhr.delete<IMessageRes, IMessageRes>(`/admin/change-logs/${id}`);
};
