// Core
import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
// Packages
import { Avatar as AntAvatar } from 'antd';
import { ScreenSizeMap } from 'antd/lib/_util/responsiveObserve';

interface IAvatarProps extends HTMLAttributes<HTMLSpanElement> {
  alt?: string;
  gap?: number;
  icon?: ReactNode;
  shape?: 'circle' | 'square';
  size?: 'large' | 'small' | 'default' | number | ScreenSizeMap;
  src?: string | ReactNode;
  srcSet?: string;
  draggable?: boolean;
  onError?: () => boolean;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Avatars can be used to represent people or objects. It supports images, Icons, or letters.
 *
 * @param alt - This attribute defines the alternative text describing the image
 * @param gap - Letter type unit distance between left and right sides
 * @param icon - Custom icon type for an icon avatar
 * @param shape - The shape of avatar
 * @param size - The size of the avatar
 * @param src - The address of the image for an image avatar or image element
 * @param srcSet - A list of sources to use for different screen resolutions
 * @param draggable - Whether the picture is allowed to be dragged
 * @param onError - Handler when img load error, return false to prevent default fallback behavior
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Avatar = ({
  alt = 'The TimeGO Admin platform avatar',
  gap = 4,
  icon,
  shape = 'circle',
  size = 'default',
  src,
  srcSet,
  draggable,
  onError,
  className,
  style,
  children,
  ...props
}: IAvatarProps) => (
  <AntAvatar
    alt={alt}
    gap={gap}
    icon={icon}
    shape={shape}
    size={size}
    src={src}
    srcSet={srcSet}
    draggable={draggable}
    onError={onError}
    className={className}
    style={style}
    {...props}
  >
    {children}
  </AntAvatar>
);

export default Avatar;
