// Core
import { CSSProperties, MouseEventHandler, ReactNode } from 'react';
// Packages
import { Tag as AntTag } from 'antd';
import { LiteralUnion } from 'antd/lib/_util/type';
import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import cn from 'classnames';
// Styles
import styles from './statusTag.module.scss';

interface IStatusTagProps {
  children?: ReactNode;
  className?: string;
  closable?: boolean;
  closeIcon?: ReactNode;
  color?: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
  icon?: ReactNode;
  visible?: boolean;
  style?: CSSProperties;
  onClose?: () => void;
  onClick?: () => void;
  onMouseDown?: MouseEventHandler<HTMLSpanElement> | undefined;
}

/**
 * Tag for categorizing or markup.
 *
 * @description It can be used to tag by dimension or property. When categorizing.
 *
 * @param children - Some ReactNode
 * @param className - The picker className
 * @param closable - Whether the Tag can be closed
 * @param closeIcon - Whether the Tag can be closed
 * @param color - Color of the Tag
 * @param icon - Set the icon of tag
 * @param visible - Whether the Tag is closed or not
 * @param onClose - Callback executed when tag is closed
 * @param style - To customize the styles
 */

const StatusTag = ({
  children,
  className = '',
  closable = false,
  closeIcon,
  color,
  icon,
  visible = true,
  style,
  onClose,
  onClick,
  onMouseDown,
}: IStatusTagProps) => {
  const classNames = cn(styles.statusTag, className, {
    [styles.disabled]: color === 'disabled',
  });
  return (
    <AntTag
      className={classNames}
      closable={closable}
      closeIcon={closeIcon}
      color={color}
      icon={icon}
      visible={visible}
      style={style}
      onClick={onClick}
      onClose={onClose}
      onMouseDown={onMouseDown}
    >
      {children}
    </AntTag>
  );
};

export default StatusTag;
