// Core
import { Suspense, useEffect } from 'react';
// Packages
import { Routes, Route } from 'react-router-dom';
// Components
import NotFoundPage from 'pages/NotFoundPage';
import { ErrorBoundary, Header, Loader } from 'components';
// CustomHooks
import { useRoutePermissions } from './hooks/usePermissions';
// Redux
import { STORE_KEYS, useAppSelector, useAppDispatch } from 'modules/redux/store';
import { getCurrentUserAsync } from 'modules/redux/auth/authThunk';
// Modules
import storage from 'modules/storage';
// Router
import routes from 'router/routes';
import PublicRoute from 'router/PublicRoute';
import PrivateRoute from 'router/PrivateRoute';

const App = () => {
  const dispatch = useAppDispatch();

  const { isLoggedIn, data, loading } = useAppSelector(STORE_KEYS.AUTH);
  const isAuth = !!isLoggedIn && !!data?.userId;

  useEffect(() => {
    isAuth && dispatch(getCurrentUserAsync());
  }, [dispatch, isAuth]);

  const allowedRoutes = useRoutePermissions(routes);

  if (loading && storage.getData().userId) {
    return <Loader isLoading={loading} />;
  }

  return (
    <ErrorBoundary>
      <Header />

      <Suspense fallback={<Loader />}>
        <Routes>
          {allowedRoutes.map(({ isPrivate, path, component, isRestricted }) =>
            isPrivate ? (
              <Route
                key={path}
                path={path}
                element={<PrivateRoute component={component} />}
              />
            ) : (
              <Route
                key={path}
                path={path}
                element={
                  <PublicRoute component={component} isRestricted={isRestricted} />
                }
              />
            ),
          )}

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
