// Packages
import { Link } from 'react-router-dom';
// Components
import AuthMenu from 'components/AuthMenu';
import { GridRow, GridCol, MainHeader, Paragraph } from 'components/lib';
import Navigation from './Navigation';
// Custom Hooks
import { useClearCacheAndReloadAppAfterNewBuild } from 'hooks';
// Modules
import { STORE_KEYS, useAppSelector } from 'modules/redux/store';
// Router
import { ROUTES } from 'router/routes';
// Assets
import { FullLogo } from 'assets/icons';
// Styles
import styles from './header.module.scss';

const Header = () => {
  const { isLoggedIn } = useAppSelector(STORE_KEYS.AUTH);

  useClearCacheAndReloadAppAfterNewBuild();

  return (
    <MainHeader data-testid="header" className={styles.authHeader}>
      <GridRow align="middle" justify="space-between" style={{ width: '100%' }}>
        <GridCol>
          <Paragraph className={styles.logoWrap}>
            <Link to={ROUTES.SIGN_IN}>
              <FullLogo />
            </Link>
          </Paragraph>
        </GridCol>
        {isLoggedIn && (
          <GridCol>
            <Navigation />
          </GridCol>
        )}

        <GridCol>{isLoggedIn && <AuthMenu />}</GridCol>
      </GridRow>
    </MainHeader>
  );
};

export default Header;
