// Packages
import { useDispatch, useSelector } from 'react-redux';
import { configureStore, combineReducers, CombinedState } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
// Modules
import authSlice, { IAuthSliceState } from 'modules/redux/auth/authSlice';
import companySlice, { ICompanySliceState } from 'modules/redux/company/companySlice';
import userSlice, { IUserSliceState } from 'modules/redux/user/userSlice';
import partnerSlice, { IPartnerSliceState } from './partner/partnerSlice';
import changeLogSlice, { IChangeLogSliceState } from './changeLogs/changeLogSlice';

export const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [companySlice.name]: companySlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [partnerSlice.name]: partnerSlice.reducer,
  [changeLogSlice.name]: changeLogSlice.reducer,
});

type TMiddleware = CurriedGetDefaultMiddleware<
  CombinedState<{
    auth: IAuthSliceState;
    company: ICompanySliceState;
    user: IUserSliceState;
    partner: IPartnerSliceState;
    changeLog: IChangeLogSliceState;
  }>
>;
export const middleware = (getDefaultMiddleware: TMiddleware) => getDefaultMiddleware();

// STORE INIT
const store = configureStore({ reducer: rootReducer, middleware });

// ROOT STATE
type RootState = ReturnType<typeof store.getState>;

// USE DISPATCH
type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// USE SELECTOR
type TStoreKeys = keyof RootState;
const getConnectedStore =
  <K extends TStoreKeys>(key: K) =>
  () =>
    useSelector((state: RootState) => state[key]);

export const useAppSelector = <K extends TStoreKeys>(key: K) => getConnectedStore(key)();

export const STORE_KEYS = {
  AUTH: authSlice.name,
  COMPANY: companySlice.name,
  USER: userSlice.name,
  PARTNER: partnerSlice.name,
  CHANGE_LOG: changeLogSlice.name,
};

export default store;
