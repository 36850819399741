// Core
import {
  ReactNode,
  ChangeEvent,
  CSSProperties,
  FocusEventHandler,
  LegacyRef,
  KeyboardEvent,
} from 'react';
// Packages
import { Input as AntTextInput } from 'antd';
import cn from 'classnames';
// Styles
import styles from './textInput.module.scss';

interface ITextInputProps {
  autoComplete?: string;
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  allowClear?: boolean;
  bordered?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  prefix?: ReactNode;
  size?: 'small' | 'middle' | 'large';
  suffix?: ReactNode;
  readOnly?: boolean;
  ref?: LegacyRef<AntTextInput>;
  value?: string;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  className?: string;
  style?: CSSProperties;
}

/**
 * A basic widget for getting the user input is a text field.
 * Keyboard and mouse can be used for providing or changing data.
 *
 * @description A user input in a form field is needed. A search input is required.
 * @param addonAfter - The label text displayed after (on the right side of) the input field
 * @param addonBefore - The label text displayed before (on the left side of) the input field
 * @param allowClear - If allow to remove input content with clear icon
 * @param autoComplete - Browser autocomplete
 * @param bordered - Whether has border style
 * @param defaultValue - The initial input content
 * @param disabled - 	Whether the input is disabled
 * @param id - The ID for input
 * @param maxLength - The max length
 * @param prefix - The prefix icon for the Input
 * @param size - The size of the input box. Note: in the context of a form, the "large" size is used
 * @param suffix - The suffix icon for the Input
 * @param value - The input content value
 * @param onChange - Callback when user input
 * @param placeholder - Specifies a short hint that describes the expected value of an input field
 * @param className - Component className
 * @param style - To customize the styles
 */

const TextInput = ({
  addonAfter,
  addonBefore,
  allowClear = false,
  autoComplete = 'off',
  bordered = true,
  defaultValue,
  disabled = false,
  id,
  maxLength,
  prefix,
  size = 'middle',
  suffix,
  readOnly = false,
  ref,
  value,
  onBlur,
  onChange,
  onKeyDown,
  placeholder = '',
  required = false,
  className,
  style,
}: ITextInputProps) => {
  const classNames = cn(
    styles.formInput,
    { [styles.formInputHasAffix]: prefix || suffix },
    className,
  );

  return (
    <AntTextInput
      required={required}
      addonAfter={addonAfter}
      addonBefore={addonBefore}
      allowClear={allowClear}
      bordered={bordered}
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      prefix={prefix}
      size={size}
      suffix={suffix}
      autoComplete={autoComplete}
      type="text"
      readOnly={readOnly}
      ref={ref}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      className={classNames}
      style={style}
    />
  );
};

export default TextInput;
