interface IProps {
  primaryColor?: string;
}

/**
 * @param primaryColor - The background logo color. By default '#1B96FF'.
 */

const Dot = ({ primaryColor = '#1B96FF' }: IProps) => (
  <svg
    width="25"
    height="16"
    viewBox="0 0 25 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.75" cy="7.99988" r="2" fill={primaryColor} />
  </svg>
);

export default Dot;
