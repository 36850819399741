// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Menu as AntMenu } from 'antd';
import { RenderIconInfo, MenuInfo, SelectInfo } from 'rc-menu/lib/interface.d';

const { Item, SubMenu, ItemGroup } = AntMenu;

export type TMenuInfo = MenuInfo;

interface IMenuProps {
  className?: string;
  defaultOpenKeys?: string[];
  defaultSelectedKeys?: string[];
  expandIcon?: ReactNode | ((props: RenderIconInfo) => React.ReactNode);
  forceSubMenuRender?: boolean;
  inlineIndent?: number;
  mode?: 'vertical' | 'horizontal' | 'inline';
  multiple?: boolean;
  openKeys?: string[];
  overflowedIndicator?: ReactNode;
  selectable?: boolean;
  selectedKeys?: string[];
  style?: CSSProperties;
  subMenuCloseDelay?: number;
  subMenuOpenDelay?: number;
  theme?: 'light' | 'dark';
  triggerSubMenuAction?: 'hover' | 'click';
  onClick?: (info: MenuInfo) => void;
  onDeselect?: (info: SelectInfo) => void;
  onOpenChange?: (openKeys: React.Key[]) => void;
  onSelect?: (info: SelectInfo) => void;
  children?: ReactNode;
}

/**
 * A versatile menu for navigation.
 *
 * @param className - Container className
 * @param defaultOpenKeys - Array with the keys of default opened sub menus
 * @param defaultSelectedKeys - Array with the keys of default selected menu items
 * @param expandIcon - custom expand icon of submenu
 * @param forceSubMenuRender - Render submenu into DOM before it becomes visible
 * @param inlineIndent - Indent (in pixels) of inline menu items on each level
 * @param mode - Type of menu
 * @param multiple - Allows selection of multiple items
 * @param openKeys - Array with the keys of currently opened sub-menus
 * @param overflowedIndicator - Customized icon when menu is collapsed
 * @param selectable - Allows selecting menu items
 * @param selectedKeys - Array with the keys of currently selected menu items
 * @param style - Style of the root node
 * @param subMenuCloseDelay - Delay time to hide submenu when mouse leaves (in seconds)
 * @param subMenuOpenDelay - Delay time to show submenu when mouse enters, (in seconds)
 * @param theme - Color theme of the menu
 * @param triggerSubMenuAction - Which action can trigger submenu open/close
 * @param onClick - Called when a menu item is clicked
 * @param onDeselect - Called when a menu item is deselected (multiple mode only)
 * @param onOpenChange - Called when sub-menus are opened or closed
 * @param onSelect - Called when a menu item is selected
 * @param children - Some ReactNode
 */

const Menu = ({
  className,
  children,
  defaultOpenKeys,
  defaultSelectedKeys,
  expandIcon,
  forceSubMenuRender = false,
  inlineIndent = 24,
  mode = 'vertical',
  multiple = false,
  openKeys,
  overflowedIndicator,
  selectable = true,
  selectedKeys,
  style,
  subMenuCloseDelay = 0.1,
  subMenuOpenDelay = 0,
  theme = 'light',
  triggerSubMenuAction = 'hover',
  onClick,
  onDeselect,
  onOpenChange,
  onSelect,
}: IMenuProps) => (
  <AntMenu
    className={className}
    defaultOpenKeys={defaultOpenKeys}
    defaultSelectedKeys={defaultSelectedKeys}
    expandIcon={expandIcon}
    forceSubMenuRender={forceSubMenuRender}
    inlineIndent={inlineIndent}
    mode={mode}
    multiple={multiple}
    openKeys={openKeys}
    overflowedIndicator={overflowedIndicator}
    selectable={selectable}
    selectedKeys={selectedKeys}
    style={style}
    subMenuCloseDelay={subMenuCloseDelay}
    subMenuOpenDelay={subMenuOpenDelay}
    theme={theme}
    triggerSubMenuAction={triggerSubMenuAction}
    onClick={onClick}
    onDeselect={onDeselect}
    onOpenChange={onOpenChange}
    onSelect={onSelect}
  >
    {children}
  </AntMenu>
);

Menu.Item = Item;
Menu.SubMenu = SubMenu;
Menu.ItemGroup = ItemGroup;

export default Menu;
