import { ECompanyPaidStatus } from 'types/companyTypes';

enum weekdays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

const STATUS_OPTIONS = [
  { label: ECompanyPaidStatus.Free, value: ECompanyPaidStatus.Free },
  { label: ECompanyPaidStatus.Paid, value: ECompanyPaidStatus.Paid },
  { label: ECompanyPaidStatus.Trial, value: ECompanyPaidStatus.Trial },
];

const DATE_FORMAT = 'DD-MMM-YYYY';
const DATE_FORMAT_STANDARD = 'YYYY-MM-DD';

export const ROLES = {
  admin: 'admin',
  support: 'support',
};

export const PERMISSION_KEYS = {
  COMPANIES: 'companiesStats',
  USERS: 'adminUsers',
  PARTNERS: 'partners',
  PARTNER_REPORTS: 'partnerReports',
  CHANGE_LOG: 'changeLog',
} as const;

export { weekdays, STATUS_OPTIONS, DATE_FORMAT, DATE_FORMAT_STANDARD };

export const NOT_FOUND = ['PARTNER_NOT_FOUND', 'USER_NOT_FOUND'];

export const STATUS_TAG_COLOR = '#2E3A59';
