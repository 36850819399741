// Core
import { ReactNode, CSSProperties, MouseEvent } from 'react';
// Packages
import { ButtonProps, Modal as AntModal } from 'antd';
import { LegacyButtonType } from 'antd/lib/button/button';
// Styles
import styles from './modal.module.scss';

type TGetContainerFunc = () => HTMLElement;

interface IModalProps {
  afterClose?: () => void;
  bodyStyle?: CSSProperties;
  cancelButtonProps?: ButtonProps;
  cancelText?: ReactNode;
  centered?: boolean;
  children?: ReactNode;
  className?: string;
  closable?: boolean;
  closeIcon?: ReactNode;
  confirmLoading?: boolean;
  destroyOnClose?: boolean;
  focusTriggerAfterClose?: boolean;
  footer?: ReactNode;
  content?: ReactNode;
  forceRender?: boolean;
  getContainer?: string | HTMLElement | TGetContainerFunc | false;
  keyboard?: boolean;
  mask?: boolean;
  maskClosable?: boolean;
  maskStyle?: CSSProperties;
  modalRender?: (node: ReactNode) => ReactNode;
  okButtonProps?: ButtonProps;
  okText?: ReactNode;
  okType?: LegacyButtonType;
  style?: CSSProperties;
  title?: ReactNode;
  visible?: boolean;
  width?: string | number;
  wrapClassName?: string;
  zIndex?: number;
  onCancel?: (e: MouseEvent<HTMLElement>) => void;
  onOk?: (e: MouseEvent<HTMLElement>) => void;
}

/**
 * Modal dialogs.
 *
 * @description When requiring users to interact with the application,
 * but without jumping to a new page and interrupting the user's workflow,
 * you can use Modal to create a new floating layer over the current page to get user feedback or display information.
 * Additionally, if you need show a simple confirmation dialog, you can use antd.Modal.confirm(), and so on.
 *
 * @param afterClose - Specify a function that will be called when modal is closed completely
 * @param bodyStyle - Body style for modal body element. Such as height, padding etc
 * @param cancelButtonProps - The cancel button props
 * @param cancelText - Text of the Cancel button
 * @param centered - Centered Modal
 * @param children - Some ReactNode
 * @param className - Component className
 * @param closable - Whether a close (x) button is visible on top right of the modal dialog or not
 * @param closeIcon - Custom close icon
 * @param confirmLoading - Whether to apply loading visual effect for OK button or not
 * @param content - Content
 * @param destroyOnClose - Whether to unmount child components on onClose
 * @param focusTriggerAfterClose - Whether need to focus trigger element after dialog is closed
 * @param footer - Footer content, set as footer={null} when you don't need default buttons
 * @param forceRender - Force render Modal
 * @param getContainer - Return the mount node for Modal
 * @param keyboard - Whether support press esc to close
 * @param mask - Whether show mask or not
 * @param maskClosable - Whether to close the modal dialog when the mask (area outside the modal) is clicked
 * @param maskStyle - Style for modal's mask element
 * @param modalRender - Custom modal content render
 * @param okButtonProps - The ok button props
 * @param okText - Text of the OK button
 * @param okType - Button type of the OK button
 * @param style - Style of floating layer, typically used at least for adjusting the position
 * @param title - The modal dialog's title
 * @param visible - Whether the modal dialog is visible or not
 * @param width - Width of the modal dialog
 * @param wrapClassName - The class name of the container of the modal dialog
 * @param zIndex - The z-index of the Modal
 * @param onCancel - Specify a function that will be called when a user clicks mask, close button on top right or Cancel button
 * @param onOk - Specify a function that will be called when a user clicks the OK button
 */

const Modal = ({
  afterClose,
  bodyStyle,
  cancelButtonProps,
  cancelText = 'Cancel',
  centered = false,
  children,
  className,
  closable = true,
  closeIcon,
  confirmLoading = false,
  destroyOnClose = false,
  focusTriggerAfterClose = true,
  footer,
  forceRender = false,
  getContainer,
  keyboard = true,
  mask = true,
  maskClosable = true,
  maskStyle = { background: 'rgba(46, 58, 89, 0.4)' },
  modalRender,
  okButtonProps,
  okText = 'Ok',
  okType = 'primary',
  style,
  title,
  visible = false,
  width = 520,
  wrapClassName,
  zIndex = 1000,
  onCancel,
  onOk,
}: IModalProps) => (
  <AntModal
    afterClose={afterClose}
    bodyStyle={bodyStyle}
    cancelButtonProps={cancelButtonProps}
    cancelText={cancelText}
    centered={centered}
    className={`${styles.modalWindow} ${className}`}
    closable={closable}
    closeIcon={closeIcon}
    confirmLoading={confirmLoading}
    destroyOnClose={destroyOnClose}
    focusTriggerAfterClose={focusTriggerAfterClose}
    footer={footer}
    forceRender={forceRender}
    getContainer={getContainer}
    keyboard={keyboard}
    mask={mask}
    maskClosable={maskClosable}
    maskStyle={maskStyle}
    modalRender={modalRender}
    okButtonProps={okButtonProps}
    okText={okText}
    okType={okType}
    style={style}
    title={title}
    visible={visible}
    width={width}
    wrapClassName={wrapClassName}
    zIndex={zIndex}
    onCancel={onCancel}
    onOk={onOk}
  >
    {children}
  </AntModal>
);

Modal.useModal = AntModal.useModal;

Modal.info = AntModal.info;
Modal.success = AntModal.success;
Modal.error = AntModal.error;
Modal.warning = AntModal.warning;
Modal.confirm = AntModal.confirm;

export default Modal;
