/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-vars */
// Core
import { useState, useEffect } from 'react';
// Packages
import { useLocation, useSearchParams } from 'react-router-dom';
// Components
import Table, { TTablePaginationConfig } from 'components/Table';
// Helpers
import {
  getSortOrder,
  setSortOrder,
  TSortOrderKey,
  TSortOrderValue,
} from 'helpers/sortOrder';
import { getQueryParams, updatePageUrl } from 'helpers/queryString';

type TSortOrder = { [key: string]: TSortOrderKey };

const useTable = () => {
  const location = useLocation();

  const [, setSearchParams] = useSearchParams();

  const [pagination, setPagination] = useState(Table.paginationSettings);
  const [columnSortOrder, setColumnSortOrder] = useState<TSortOrder>({});

  // Set pagination state after reloading page
  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    if (!queryParams.page || !queryParams.limit) {
      return;
    }

    const { page, limit } = queryParams;
    const isSamePage = pagination.current === Number(page);
    const isSameLimit = pagination.pageSize === Number(limit);

    if (isSamePage && isSameLimit) {
      return;
    }

    setPagination(prev => ({ ...prev, current: Number(page), pageSize: Number(limit) }));
  }, [location.search, pagination]);

  // Set sort order state after reloading page
  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    if (!queryParams.sortColumn || !queryParams.sortOrder) {
      return;
    }

    const sortOrder = setSortOrder(queryParams.sortOrder as TSortOrderValue);

    setColumnSortOrder({ [queryParams.sortColumn as string]: sortOrder });
  }, [location.search]);

  // Change table handler (with change sort and pagination state)
  const handleTableChange = (pagination: TTablePaginationConfig, _: any, sorter: any) => {
    const { current: page, pageSize: limit } = pagination;
    const { columnKey, order } = sorter;

    const sortOrder = getSortOrder(order);
    const sortColumn = sortOrder && columnKey;

    const prevQueryParams = getQueryParams(location.search);
    const newQueryParams = { limit, page, sortColumn, sortOrder };

    updatePageUrl(prevQueryParams, newQueryParams, setSearchParams);

    setColumnSortOrder({ [columnKey as string]: order });
    setPagination(pagination);
  };

  return { pagination, columnSortOrder, handleTableChange };
};

export default useTable;
