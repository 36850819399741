// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Radio as AntRadio, RadioChangeEvent } from 'antd';

type TRadioValueType = string | number | boolean;

interface IRadioProps {
  autoFocus?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  value?: TRadioValueType;
  onChange?: (e: RadioChangeEvent) => void;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Radio.
 *
 * @description Used to select a single state from multiple options.
 * The difference from Select is that Radio is visible to the user and
 * can facilitate the comparison of choice, which means there shouldn't be too many of them.
 *
 * @param autoFocus - Whether get focus when component mounted
 * @param checked - Specifies whether the radio is selected
 * @param defaultChecked - Specifies the initial state: whether or not the radio is selected
 * @param disabled - Disable radio
 * @param value - According to value for comparison, to determine whether the selected
 * @param onChange - The callback function that is triggered when the state changes
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Radio = ({
  autoFocus = false,
  checked = false,
  defaultChecked = false,
  disabled = false,
  value,
  onChange,
  className,
  style,
  children,
}: IRadioProps) => (
  <AntRadio
    autoFocus={autoFocus}
    checked={checked}
    defaultChecked={defaultChecked}
    disabled={disabled}
    value={value}
    onChange={onChange}
    className={className}
    style={style}
  >
    {children}
  </AntRadio>
);

export default Radio;
