// Core
import { ChangeEvent, CSSProperties } from 'react';
// Packages
import { AiOutlineSearch } from 'react-icons/ai';
import { Input as AntSearchInput, InputProps } from 'antd';
// Styles
import styles from './searchInput.module.scss';

interface ISearchInputProps extends InputProps {
  allowClear?: boolean;
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  searchIconPosition?: 'before' | 'after';
  size?: 'small' | 'middle' | 'large';
  value?: string;
  style?: CSSProperties;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const positions = {
  after: { suffix: <AiOutlineSearch size="16px" /> },
  before: { prefix: <AiOutlineSearch size="16px" /> },
};

type TIconPositionKeys = keyof typeof positions;

/**
 * A basic widget for getting the user input is a search field.
 * Keyboard and mouse can be used for providing or changing data.
 *
 * @description A user input in a form field is needed. A search input is required.
 *
 * @param allowClear - If allow to remove input content with clear icon
 * @param className - The picker className
 * @param placeholder - Specifies a short hint that describes the expected value of an input field
 * @param defaultValue - Specifies initialValue
 * @param searchIconPosition - The suffix or prefix icon for the Input
 * @param size - The size of the input box. Note: in the context of a form, the large size is used
 * @param style - To customize the styles
 * @param onChange - Change input value
 * @param value - The input content value
 */

const SearchInput = ({
  allowClear = false,
  className,
  placeholder = '',
  defaultValue,
  searchIconPosition = 'before',
  size = 'middle',
  value,
  style,
  onChange,
  ...props
}: ISearchInputProps) => {
  const iconPosition = positions[searchIconPosition as TIconPositionKeys];

  return (
    <AntSearchInput
      allowClear={allowClear}
      className={`${styles.searchInput} ${className}`}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      size={size}
      style={style}
      type="text"
      value={value}
      {...iconPosition}
      {...props}
    />
  );
};

export default SearchInput;
