// Core
import { CSSProperties, Ref, KeyboardEventHandler } from 'react';
// Packages
import { InputNumber as AntNumberInput } from 'antd';
// Styles
import styles from './numberInput.module.scss';

export type ValueType = string | number;

interface INumberInputProps<T extends ValueType> {
  autoFocus?: boolean;
  bordered?: boolean;
  className?: string;
  decimalSeparator?: string;
  defaultValue?: T;
  disabled?: boolean;
  formatter?: (value: T | undefined) => string;
  keyboard?: boolean;
  max?: T;
  min?: T;
  parser?: (displayValue: string | undefined) => T;
  placeholder?: string;
  precision?: number;
  readOnly?: boolean;
  size?: 'small' | 'middle' | 'large';
  step?: ValueType;
  stringMode?: boolean;
  style?: CSSProperties;
  ref?: Ref<HTMLInputElement>;
  value?: T;
  onChange?: (value: string | number | null) => void;
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
  onStep?: (value: T, info: { offset: ValueType; type: 'up' | 'down' }) => void;
}

/**
 * Enter a number within certain range with the mouse or keyboard.
 *
 * @description When a numeric value needs to be provided.
 *
 * @param autoFocus - If get focus when component mounted
 * @param bordered - Whether has border style
 * @param className - Component className
 * @param decimalSeparator - Decimal separator
 * @param defaultValue - The initial value
 * @param disabled - If disable the input
 * @param formatter - Specifies the format of the value presented
 * @param keyboard - If enable keyboard behavior
 * @param max - The max value
 * @param min - The min value
 * @param parser - Specifies the value extracted from formatter
 * @param placeholder - Specifies a short hint that describes the expected value of an input field
 * @param precision - The precision of input value. Will use formatter when config of formatter
 * @param readOnly - If readonly the input
 * @param size - The height of input box
 * @param step - The number to which the current value is increased or decreased. It can be an integer or decimal
 * @param stringMode - Set value as string to support high precision decimals. Will return string value by onChange
 * @param style - To customize the styles
 * @param value - The current value
 * @param onChange - The callback triggered when the value is changed
 * @param onPressEnter - The callback function that is triggered when Enter key is pressed
 * @param onStep - The callback function that is triggered when click up or down buttons
 */

const NumberInput = ({
  autoFocus = false,
  bordered = true,
  className,
  decimalSeparator,
  defaultValue,
  disabled = false,
  formatter,
  keyboard = true,
  max,
  min = '0.00',
  parser,
  placeholder = '',
  precision,
  readOnly = false,
  size = 'middle',
  step = '0.50',
  stringMode = false,
  style,
  ref,
  value,
  onChange,
  onPressEnter,
  onStep,
}: INumberInputProps<number | string>) => (
  <AntNumberInput
    autoFocus={autoFocus}
    bordered={bordered}
    className={`${styles.numberInput} ${className}`}
    decimalSeparator={decimalSeparator}
    defaultValue={defaultValue}
    disabled={disabled}
    formatter={formatter}
    keyboard={keyboard}
    max={max}
    min={min}
    parser={parser}
    placeholder={placeholder}
    precision={precision}
    readOnly={readOnly}
    size={size}
    step={step}
    stringMode={stringMode}
    style={style}
    ref={ref}
    value={value}
    onChange={onChange}
    onPressEnter={onPressEnter}
    onStep={onStep}
  />
);

export default NumberInput;
