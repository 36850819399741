export enum SORT_ORDER_KEYS {
  descend = 'DESC',
  ascend = 'ASC',
}

export enum SORT_ORDER_VALUES {
  DESC = 'descend',
  ASC = 'ascend',
}

export type TSortOrderKey = keyof typeof SORT_ORDER_KEYS;
export type TSortOrderValue = keyof typeof SORT_ORDER_VALUES;

export const getSortOrder = (order: TSortOrderKey | undefined): TSortOrderValue =>
  SORT_ORDER_KEYS[order as TSortOrderKey];

export const setSortOrder = (order: TSortOrderValue | undefined): TSortOrderKey =>
  SORT_ORDER_VALUES[order as TSortOrderValue];
