// Components
import { Spinner } from 'components/lib';
// Styles
import styles from './loader.module.scss';

interface IProps {
  isLoading?: boolean;
}

const Loader = ({ isLoading = true }: IProps) => (
  <div className={styles.loaderWrap}>
    <Spinner data-testid="loader" size="large" spinning={isLoading} />
  </div>
);

export default Loader;
