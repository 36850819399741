// Redux
import { STORE_KEYS, useAppSelector } from 'modules/redux/store';
// Helpers
import { PERMISSION_KEYS } from 'helpers/constants';
// Interfaces and types
import { TRoute } from 'router/routes';

interface IUserPagePermissions {
  read: boolean;
  edit: boolean;
  delete: boolean;
  create: boolean;
}

const permissionActions = {
  [PERMISSION_KEYS.COMPANIES]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.USERS]: ['read', 'create', 'edit', 'delete'],
  [PERMISSION_KEYS.PARTNERS]: ['read', 'create', 'edit', 'delete'],
};

type TPermissions = typeof permissionActions;

type TPermissionKey = keyof typeof permissionActions;

const useUserPermissions = (neededPermissions: TPermissionKey) => {
  const { userDetails } = useAppSelector(STORE_KEYS.AUTH);

  const neededPermissionActions = permissionActions[neededPermissions];
  const userPermissionActions = userDetails?.adminPlatformPermissions[neededPermissions];

  const allowedPermissions = userPermissionActions?.filter(permission =>
    neededPermissionActions?.includes(permission),
  );

  return allowedPermissions?.length === neededPermissionActions?.length;
};

type RouteType = TRoute;

const useRoutePermissions = <T extends RouteType[]>(routes: T): T => {
  const { userDetails } = useAppSelector(STORE_KEYS.AUTH);

  if (!userDetails) {
    return routes.filter(
      ({ isAvailable, isPrivate: privateRoute }: RouteType) =>
        isAvailable && !privateRoute,
    ) as T;
  }

  const isPermissionsMatch = (route: RouteType, permissions: TPermissions) => {
    return permissions[route.permissionKey as TPermissionKey].includes('read');
  };

  const allowedRoutes = routes.filter(route => {
    return (
      route.isAvailable || isPermissionsMatch(route, userDetails.adminPlatformPermissions)
    );
  });

  return allowedRoutes as T;
};

const useUserPermissionsDetails = (neededPermissions: TPermissionKey) => {
  const { userDetails } = useAppSelector(STORE_KEYS.AUTH);
  const userPermissionActions =
    userDetails?.adminPlatformPermissions[neededPermissions] || [];

  let userPagePermission: IUserPagePermissions = {
    read: false,
    edit: false,
    delete: false,
    create: false,
  };

  const pagePermissions = (userPermissionActions as string[])?.reduce(
    (acc: IUserPagePermissions, curr: string) => {
      return { ...acc, [curr]: true };
    },
    userPagePermission,
  );

  return pagePermissions;
};

export { useUserPermissions, useRoutePermissions, useUserPermissionsDetails };

export {};
