import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import packageJson from '../../package.json';

const buildDateGreaterThan = (latestDate: number, currentDate: number) => {
  if (latestDate > currentDate) {
    return true;
  } else {
    return false;
  }
};

// hook to check the app version on route change
function useClearCacheAndReloadAppAfterNewBuild() {
  // check if a version update (refresh) needed on route change
  let location = useLocation();

  useLayoutEffect(() => {
    // if there is an update available and no state passed to route
    fetch('/meta.json')
      .then(response => response.json())
      .then(meta => {
        const latestVersionDate = meta?.buildDateTime as number;
        const currentVersionDate = Number(packageJson?.buildDateTime) as number;

        const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
        if (shouldForceRefresh) {
          window.location.reload();
        }
      });
  }, [location]);
}

export default useClearCacheAndReloadAppAfterNewBuild;
