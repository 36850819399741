// Core
import { CSSProperties, ReactElement, ReactNode, MouseEvent } from 'react';
// Packages
import cn from 'classnames';
import { Select as AntSelect } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { FilterFunc, BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
// Styles
import styles from './select.module.scss';

export type CustomTagProps = {
  label: React.ReactNode;
  value: any;
  disabled: boolean;
  onClose: (event?: any) => void;
  closable: boolean;
};

export type TValueType =
  | string
  | string[]
  | number
  | number[]
  | LabeledValue
  | LabeledValue[];

interface IOptionCoreData {
  key?: any;
  disabled?: boolean;
  value: any;
  title?: string;
  className?: string;
  style?: CSSProperties;
  label?: ReactNode;
  children?: ReactNode;
}

export interface IOptionData extends IOptionCoreData {
  /** Save for customize data */
  [prop: string]: any;
}

interface IOptionGroupData {
  key?: any;
  label?: ReactNode;
  options: IOptionData[];
  className?: string;
  style?: CSSProperties;
  /** Save for customize data */
  [prop: string]: any;
}

export type OptionsType = (BaseOptionType | DefaultOptionType)[];
export type TSearchOption = IOptionData | IOptionGroupData | undefined;
export type ArrayElementType<T> = T extends (infer E)[] ? E : T;

interface ISelectProps {
  allowClear?: boolean;
  autoClearSearchValue?: boolean;
  autoFocus?: boolean;
  bordered?: boolean;
  className?: string;
  children?: ReactNode;
  clearIcon?: ReactNode;
  color?: 'default' | 'secondary' | 'error' | 'success' | 'warning';
  dataCy?: string;
  defaultActiveFirstOption?: boolean;
  defaultOpen?: boolean;
  defaultValue?: TValueType;
  disabled?: boolean;
  dropdownClassName?: string;
  dropdownMatchSelectWidth?: boolean | number;
  dropdownRender?: (menu: ReactElement) => ReactElement;
  dropdownStyle?: CSSProperties;
  filterOption?: boolean | FilterFunc<BaseOptionType | DefaultOptionType>;
  filterSort?: (optionA: OptionsType[number], optionB: OptionsType[number]) => number;
  getPopupContainer?: (props: any) => HTMLElement;
  label?: string;
  labelInValue?: boolean;
  listHeight?: number;
  loading?: boolean;
  maxTagCount?: number | 'responsive';
  maxTagPlaceholder?: ReactNode;
  maxTagTextLength?: number;
  menuItemSelectedIcon?: ReactNode;
  mode?: 'multiple' | 'tags';
  notFoundContent?: ReactNode;
  open?: boolean;
  optionFilterProp?: string;
  optionLabelProp?: string;
  options?: OptionsType;
  placeholder?: ReactNode;
  removeIcon?: ReactNode;
  searchValue?: string;
  showArrow?: boolean;
  showSearch?: boolean;
  size?: 'large' | 'middle' | 'small';
  style?: CSSProperties;
  suffixIcon?: ReactNode;
  tagRender?: (props: CustomTagProps) => ReactElement;
  tokenSeparators?: string[];
  value?: TValueType;
  virtual?: boolean;
  onBlur?: () => void;
  onChange?: (value: TValueType) => void;
  onClear?: () => void;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  onDeselect?: (value: any, option: OptionsType[number]) => void;
  onDropdownVisibleChange?: (open: boolean) => void;
  onFocus?: () => void;
  onInputKeyDown?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onPopupScroll?: () => void;
  onSearch?: (value: string) => void;
  onSelect?: (value: string | number | LabeledValue, option: OptionsType[number]) => void;
}

/**
 * Select component to select value from options.
 *
 * @description A dropdown menu for displaying choices - an elegant alternative to the native <select> element.
 * Utilizing Radio is recommended when there are fewer total options (less than 5).
 *
 * @param allowClear - Show clear button
 * @param autoClearSearchValue - Whether the current search will be cleared on selecting an item. Only applies when mode is set to multiple or tags
 * @param autoFocus - Get focus by default
 * @param bordered - Whether has border style
 * @param children - Some ReactNode
 * @param className - The select className
 * @param clearIcon - The custom clear icon
 * @param dataCy - Param for testing purpose
 * @param defaultActiveFirstOption - Whether active first option by default
 * @param defaultOpen - Initial open state of dropdown
 * @param defaultValue - Initial selected option
 * @param disabled - Whether disabled select
 * @param dropdownClassName - The className of dropdown menu
 * @param dropdownMatchSelectWidth - Determine whether the dropdown menu and the select input are the same width. Default set min-width same as input. Will ignore when value less than select width. false will disable virtual scroll
 * @param dropdownRender - Customize dropdown content
 * @param dropdownStyle - The style of dropdown menu
 * @param filterOption - If true, filter options by input, if function, filter options against it. The function will receive two arguments, inputValue and option, if the function returns true, the option will be included in the filtered set; Otherwise, it will be excluded
 * @param filterSort - Sort function for search options sorting, see Array.sort's compareFunction
 * @param getPopupContainer - Parent Node which the selector should be rendered to. Default to body. When position issues happen, try to modify it into scrollable content and position it relative. Example
 * @param labelInValue - Whether to embed label in value, turn the format of value from string to { value: string, label: ReactNode }
 * @param listHeight - Config popup height
 * @param loading - Indicate loading state
 * @param maxTagCount - Max tag count to show. responsive will cost render performance
 * @param maxTagPlaceholder - Placeholder for not showing tags
 * @param maxTagTextLength - Max tag text length to show
 * @param menuItemSelectedIcon - The custom menuItemSelected icon with multiple options
 * @param mode - Set mode of Select
 * @param notFoundContent - Specify content to show when no result matches
 * @param open - Controlled open state of dropdown
 * @param optionFilterProp - Which prop value of option will be used for filter if filterOption is true. If options is set, it should be set to label
 * @param optionLabelProp - Which prop value of option will render as content of select. Example
 * @param options - Select options. Will get better perf than jsx definition
 * @param placeholder - Placeholder of select
 * @param removeIcon - The custom remove icon
 * @param searchValue - The current input "search" text
 * @param showArrow - Whether to show the drop-down arrow
 * @param showSearch - Whether show search input in single mode
 * @param size - Size of Select input
 * @param style - To customize the style
 * @param suffixIcon - The custom suffix icon
 * @param tagRender - Customize tag render
 * @param tokenSeparators - Separator used to tokenize on tag and multiple mode
 * @param value - Current selected option (considered as a immutable array)
 * @param virtual - Disable virtual scroll when set to false
 * @param onBlur - Called when blur
 * @param onChange - Called when select an option or input value change
 * @param onClear - Called when clear
 * @param onDeselect - Called when an option is deselected, param is the selected option's value. Only called for multiple or tags, effective in multiple or tags mode only
 * @param onDropdownVisibleChange - Called when dropdown open
 * @param onFocus - Called when focus
 * @param onInputKeyDown - Called when key pressed
 * @param onMouseEnter - Called when mouse enter
 * @param onMouseLeave - Called when mouse leave
 * @param onPopupScroll - Called when dropdown scrolls
 * @param onSearch - Callback function that is fired when input changed
 * @param onSelect - Called when an option is selected, the params are option's value (or key) and option instance
 */

const Select = ({
  allowClear = false,
  autoClearSearchValue,
  autoFocus,
  bordered,
  children,
  className,
  clearIcon,
  color = 'default',
  dataCy = '',
  defaultActiveFirstOption,
  defaultOpen,
  defaultValue,
  disabled = false,
  dropdownClassName,
  dropdownMatchSelectWidth,
  dropdownRender,
  dropdownStyle,
  filterOption,
  filterSort,
  getPopupContainer,
  labelInValue,
  listHeight,
  loading,
  maxTagCount,
  maxTagPlaceholder,
  maxTagTextLength,
  menuItemSelectedIcon,
  mode,
  notFoundContent,
  open,
  optionFilterProp,
  optionLabelProp,
  options,
  placeholder = '',
  removeIcon,
  searchValue,
  showArrow,
  showSearch,
  size = 'middle',
  style,
  suffixIcon,
  tagRender,
  tokenSeparators,
  value,
  virtual,
  onBlur,
  onChange,
  onClear,
  onClick,
  onDeselect,
  onDropdownVisibleChange,
  onFocus,
  onInputKeyDown,
  onMouseEnter,
  onMouseLeave,
  onPopupScroll,
  onSearch,
  onSelect,
  ...restProps
}: ISelectProps) => {
  const classnames = cn(
    styles.selectInput,
    {
      [styles.default]: color === 'default',
      [styles.secondary]: color === 'secondary',
      [styles.error]: color === 'error',
      [styles.success]: color === 'success',
      [styles.warning]: color === 'warning',
    },
    className,
  );

  return (
    <AntSelect
      allowClear={allowClear}
      autoClearSearchValue={autoClearSearchValue}
      autoFocus={autoFocus}
      bordered={bordered}
      className={classnames}
      clearIcon={clearIcon}
      data-cy={dataCy}
      defaultActiveFirstOption={defaultActiveFirstOption}
      defaultOpen={defaultOpen}
      defaultValue={defaultValue}
      disabled={disabled}
      dropdownClassName={dropdownClassName}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      dropdownRender={dropdownRender}
      dropdownStyle={dropdownStyle}
      filterOption={filterOption}
      filterSort={filterSort}
      getPopupContainer={getPopupContainer}
      labelInValue={labelInValue}
      listHeight={listHeight}
      loading={loading}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={maxTagPlaceholder}
      maxTagTextLength={maxTagTextLength}
      menuItemSelectedIcon={menuItemSelectedIcon}
      mode={mode}
      notFoundContent={notFoundContent}
      open={open}
      optionFilterProp={optionFilterProp}
      optionLabelProp={optionLabelProp}
      options={options}
      placeholder={placeholder}
      removeIcon={removeIcon}
      searchValue={searchValue}
      showArrow={showArrow}
      showSearch={showSearch}
      size={size}
      suffixIcon={suffixIcon}
      tagRender={tagRender}
      tokenSeparators={tokenSeparators}
      value={value}
      virtual={false}
      onBlur={onBlur}
      onChange={onChange}
      onClear={onClear}
      onClick={onClick}
      onDeselect={onDeselect}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onFocus={onFocus}
      onInputKeyDown={onInputKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPopupScroll={onPopupScroll}
      onSearch={onSearch}
      onSelect={onSelect}
      style={style}
      {...restProps}
    >
      {children}
    </AntSelect>
  );
};

Select.Option = AntSelect.Option;

export default Select;
