// Core
import { ReactNode } from 'react';
// Components
import { MainContent } from 'components/lib';
// Styles
import styles from './content.module.scss';

interface IProps {
  children: ReactNode;
}

const Content = ({ children }: IProps) => (
  <MainContent className={styles.contentContainer}>{children}</MainContent>
);

export default Content;
