// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getChangeLogsAsync,
  createChangeLogsAsync,
  getChangeLogsDetailsAsync,
  updateChangeLogsAsync,
  deleteChangeLogAsync,
} from './changeLogThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetChangeLogsRes, IGetChangeLogDetails } from 'types/changeLogTypes';
import { IErrorRes } from 'types/appTypes';

export interface IChangeLogSliceState {
  changeLogs: IGetChangeLogsRes | null;
  changeLogDetails: IGetChangeLogDetails | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IChangeLogSliceState = {
  changeLogs: null,
  changeLogDetails: null,
  error: null,
  isLoading: false,
};

const changeLogSlice = createSlice({
  name: 'changeLog',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    clearChangeLogDetails: state => {
      state.changeLogDetails = null;
    },
  },
  extraReducers: builder => {
    //* GET CHANGE_LOGS ASYNC THUNK
    builder.addCase(getChangeLogsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getChangeLogsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.changeLogs = payload;
    });
    builder.addCase(getChangeLogsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* CREATE CHANGE_LOG (one) ASYNC THUNK
    builder.addCase(createChangeLogsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createChangeLogsAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createChangeLogsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET CHANGE_LOG DETAILS ASYNC THUNK
    builder.addCase(getChangeLogsDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getChangeLogsDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.changeLogDetails = payload;
    });
    builder.addCase(getChangeLogsDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE CHANGE_LOG ASYNC THUNK
    builder.addCase(updateChangeLogsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateChangeLogsAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateChangeLogsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* DELETE CHANGE_LOG ASYNC THUNK
    builder.addCase(deleteChangeLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteChangeLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteChangeLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const { clearChangeLogDetails } = changeLogSlice.actions;

export default changeLogSlice;
