// Modules
import xhr from 'modules/xhr';
// Interfaces and types
import { IMessageRes, TQueryParams } from 'types/appTypes';
import {
  IGetCompaniesRes,
  IGetLogInAsCompanyRes,
  IDeleteCompaniesRes,
  IDeleteCompaniesParams,
  IGetCompanyDetailsRes,
  IUpdateCompanyRes,
  IUpdateCompanyParamWithBody,
  ISubscriptionItemsRes,
  TGetIndustriesRes,
} from 'types/companyTypes';

const getCompanies = (params: TQueryParams) => {
  return xhr.get<IGetCompaniesRes, IGetCompaniesRes>('/admin/companies-stats', {
    params,
  });
};

const signInAsCompany = (companyId: number) => {
  return xhr.post<IGetLogInAsCompanyRes, IGetLogInAsCompanyRes>(
    `/admin/companies/${companyId}/login`,
  );
};

const deleteCompany = ({ id, params }: IDeleteCompaniesParams) => {
  return xhr.delete<IDeleteCompaniesRes, IDeleteCompaniesRes>(`/admin/companies/${id}`, {
    params,
  });
};

const getCompanyDetails = (id: number) => {
  return xhr.get<IGetCompanyDetailsRes, IGetCompanyDetailsRes>(`/admin/companies/${id}`);
};

const updateCompany = ({ id, body }: IUpdateCompanyParamWithBody) => {
  return xhr.put<IUpdateCompanyRes, IUpdateCompanyRes>(`/admin/companies/${id}`, body);
};

const getSubscriptionVariants = () => {
  return xhr.get<ISubscriptionItemsRes, ISubscriptionItemsRes>(
    '/admin/subscription-variants',
  );
};

const logOutAllUsersFromCompany = (id: number) => {
  return xhr.post<IMessageRes, IMessageRes>(`/admin/companies/${id}/logout`);
};

const getIndustries = (params: TQueryParams) => {
  return xhr.get<TGetIndustriesRes, TGetIndustriesRes>('/industries', {
    params,
  });
};

export {
  getCompanies,
  signInAsCompany,
  deleteCompany,
  getCompanyDetails,
  updateCompany,
  getSubscriptionVariants,
  logOutAllUsersFromCompany,
  getIndustries,
};
