// Core
import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
// Packages
import { Layout as AntLayout } from 'antd';

interface IHeaderProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Handling the overall layout of a page.
 *
 * @param className - Container className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Header = ({ className, style, children, ...props }: IHeaderProps) => (
  <AntLayout.Header className={className} style={style} {...props}>
    {children}
  </AntLayout.Header>
);

export default Header;
