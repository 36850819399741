// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ChangeLogApi } from 'api';
// Interfaces and types
import {
  IGetChangeLogsRes,
  ICreateChangeLogBody,
  IGetChangeLogDetails,
  IUpdateChangeLogBodyWithParam,
} from 'types/changeLogTypes';
import { IErrorRes, IMessageRes } from 'types/appTypes';

const getChangeLogsAsync = createAsyncThunk<
  IGetChangeLogsRes,
  void,
  { rejectValue: IErrorRes }
>('changeLogs/getChangeLogs', async (params: void, { rejectWithValue }) => {
  try {
    return await ChangeLogApi.getChangeLogs();
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createChangeLogsAsync = createAsyncThunk<
  IMessageRes,
  ICreateChangeLogBody,
  { rejectValue: IErrorRes }
>(
  'changeLogs/createChangeLog',
  async (cred: ICreateChangeLogBody, { rejectWithValue }) => {
    try {
      return await ChangeLogApi.createChangeLog(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getChangeLogsDetailsAsync = createAsyncThunk<
  IGetChangeLogDetails,
  number,
  { rejectValue: IErrorRes }
>('changeLogs/getChangeLogsDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await ChangeLogApi.getChangeLogDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateChangeLogsAsync = createAsyncThunk<
  IMessageRes,
  IUpdateChangeLogBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'changeLogs/updateChangeLog',
  async (cred: IUpdateChangeLogBodyWithParam, { rejectWithValue }) => {
    try {
      return await ChangeLogApi.updateChangeLog(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteChangeLogAsync = createAsyncThunk<
  IMessageRes,
  number,
  { rejectValue: IErrorRes }
>('user/deleteUser', async (id: number, { rejectWithValue }) => {
  try {
    return await ChangeLogApi.deleteChangeLog(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getChangeLogsAsync,
  createChangeLogsAsync,
  getChangeLogsDetailsAsync,
  updateChangeLogsAsync,
  deleteChangeLogAsync,
};
