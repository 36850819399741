// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Card as AntCard, TabsProps } from 'antd';
import { CardType } from 'antd/lib/card';

interface ICardProps {
  actions?: Array<ReactNode>;
  activeTabKey?: string;
  bodyStyle?: CSSProperties;
  bordered?: boolean;
  cover?: ReactNode;
  defaultActiveTabKey?: string;
  extra?: ReactNode;
  headStyle?: CSSProperties;
  hoverable?: boolean;
  loading?: boolean;
  size?: 'default' | 'small';
  id?: string;
  tabBarExtraContent?: ReactNode;
  tabList?: Array<{ key: string; tab: ReactNode }>;
  tabProps?: TabsProps;
  title?: ReactNode;
  type?: CardType;
  onTabChange?: (key: string) => void;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Simple rectangular container. A card can be used to display content related to a single subject.
 * The content can consist of multiple elements of varying types and sizes.
 *
 * @param actions - The action list, shows at the bottom of the Card
 * @param activeTabKey - Current TabPane's key
 * @param bodyStyle - Inline style to apply to the card content
 * @param bordered - Toggles rendering of the border around the card
 * @param cover - Card cover
 * @param defaultActiveTabKey - Initial active TabPane's key, if activeTabKey is not set
 * @param extra - Content to render in the top-right corner of the card
 * @param headStyle - Inline style to apply to the card head
 * @param hoverable - Lift up when hovering card
 * @param loading - Shows a loading indicator while the contents of the card are being fetched
 * @param size - Size of card
 * @param id - Component id
 * @param tabBarExtraContent - Extra content in tab bar
 * @param tabList - List of TabPane's head
 * @param tabProps - Tabs
 * @param title - Card title
 * @param type - Card style type, can be set to inner or not set
 * @param onTabChange - Callback when tab is switched
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Card = ({
  actions,
  activeTabKey,
  bodyStyle,
  bordered = true,
  cover,
  defaultActiveTabKey,
  extra,
  headStyle,
  hoverable = false,
  loading = false,
  size = 'default',
  id,
  tabBarExtraContent,
  tabList,
  tabProps,
  title,
  type,
  onTabChange,
  className,
  style,
  children,
}: ICardProps) => (
  <AntCard
    actions={actions}
    activeTabKey={activeTabKey}
    bodyStyle={bodyStyle}
    bordered={bordered}
    cover={cover}
    defaultActiveTabKey={defaultActiveTabKey}
    extra={extra}
    headStyle={headStyle}
    hoverable={hoverable}
    loading={loading}
    size={size}
    id={id}
    tabBarExtraContent={tabBarExtraContent}
    tabList={tabList}
    tabProps={tabProps}
    title={title}
    type={type}
    onTabChange={onTabChange}
    className={className}
    style={style}
  >
    {children}
  </AntCard>
);

Card.Grid = AntCard.Grid;

export default Card;
