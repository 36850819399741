interface IProps {
  primaryColor?: string;
  secondaryColor?: string;
}

/**
 * @param primaryColor - The background logo color. By default '#1B96FF'.
 * @param secondaryColor - The color for the icon inside the logo. By default '#ffffff'.
 */

const CompanyLabel = ({
  primaryColor = '#1B96FF',
  secondaryColor = '#ffffff',
}: IProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="3" fill={primaryColor} />
    <g clipPath="url(#clip0)">
      <path
        d="M22.4072 11.6285H18.9596V10.2393C18.9596 9.57779 18.4214 9.03967 17.76 9.03967H14.24C13.5785 9.03967 13.0404 9.57779 13.0404 10.2393V11.6285H9.5928C8.71451 11.6285 8 12.343 8 13.2213V21.3679C8 22.2462 8.71451 22.9607 9.5928 22.9607H22.4072C23.2855 22.9607 24 22.2462 24 21.3679V13.2213C24 12.343 23.2855 11.6285 22.4072 11.6285ZM14.0565 10.2393C14.0565 10.1381 14.1388 10.0558 14.24 10.0558H17.76C17.8612 10.0558 17.9435 10.1381 17.9435 10.2393V11.6285H14.0565V10.2393ZM9.0161 13.2213C9.0161 12.9033 9.27479 12.6446 9.5928 12.6446H22.4072C22.7252 12.6446 22.9839 12.9033 22.9839 13.2213V13.9732C22.9839 14.6785 22.511 15.3088 21.8338 15.506L16.5201 17.0531C16.1799 17.1522 15.8202 17.1522 15.4799 17.0531L10.1662 15.5059C9.48903 15.3088 9.0161 14.6785 9.0161 13.9732V13.2213ZM22.4072 21.9446H9.5928C9.27482 21.9446 9.0161 21.686 9.0161 21.368V16.0419C9.27212 16.2398 9.56396 16.3889 9.88213 16.4815C15.752 18.1905 15.4503 18.1435 16 18.1435C16.557 18.1435 16.2411 18.1925 22.1178 16.4815C22.436 16.3889 22.7279 16.2398 22.9839 16.0419V21.3679C22.9839 21.6859 22.7252 21.9446 22.4072 21.9446Z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill={secondaryColor} transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

export default CompanyLabel;
