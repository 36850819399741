// Packages
import { createSlice } from '@reduxjs/toolkit';
// Modules
import webStorage from 'modules/storage';
import {
  forgotPasswordAsync,
  recoverPasswordAsync,
  signInFirstStepAsync,
  signInSecondStepMFAAsync,
  signOutAsync,
  getCurrentUserAsync,
} from 'modules/redux/auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { ISignInResSecondStep, ICurrentUserInfo } from 'types/authTypes';

export interface IAuthSliceState {
  data: {
    userId: null | ISignInResSecondStep['userId'];
  };
  userDetails: ICurrentUserInfo | null;
  isLoggedIn: boolean;
  error: null | IErrorRes;
  loading: boolean;
}

const initialState: IAuthSliceState = {
  data: {
    userId: webStorage.getData()?.userId || null,
  },
  isLoggedIn: !!webStorage.getData()?.tokens?.accessToken,
  userDetails: null,
  error: null,
  loading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    //* SIGN IN FIRST STEP MFA ASYNC THUNK
    builder.addCase(signInFirstStepAsync.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signInFirstStepAsync.fulfilled, state => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(signInFirstStepAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload!;
    });

    //* SIGN IN SECOND STEP MFA  ASYNC THUNK
    builder.addCase(signInSecondStepMFAAsync.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signInSecondStepMFAAsync.fulfilled, (state, { payload }) => {
      state.data = { ...payload.tokens, userId: payload.userId };
      state.isLoggedIn = true;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(signInSecondStepMFAAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload!;
    });

    //* SIGN OUT ASYNC THUNK
    builder.addCase(signOutAsync.fulfilled, state => {
      state.isLoggedIn = false;
      state.error = null;
    });

    //* FORGOT PASSWORD ASYNC THUNK
    builder.addCase(forgotPasswordAsync.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(forgotPasswordAsync.fulfilled, state => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(forgotPasswordAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload!;
    });

    //* RECOVER PASSWORD ASYNC THUNK
    builder.addCase(recoverPasswordAsync.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(recoverPasswordAsync.fulfilled, state => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(recoverPasswordAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });

    //* GET CURRENT USER ASYNC THUNK
    builder.addCase(getCurrentUserAsync.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCurrentUserAsync.fulfilled, (state, { payload }) => {
      state.userDetails = payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getCurrentUserAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload!;
    });
  },
});

export const { clearErrors } = authSlice.actions;
export default authSlice;
