// Core
import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
// Packages
import { Divider as AntDivider } from 'antd';
// Styles
import './divider.styles.scss';

interface IDividerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  dashed?: boolean;
  orientation?: 'left' | 'right' | 'center';
  plain?: boolean;
  style?: CSSProperties;
  type?: 'horizontal' | 'vertical';
  children?: ReactNode;
}

/**
 * A divider line separates different content.
 *
 * @description Divide sections of article. Divide inline text and links such as the operation column of table.
 *
 * @param className - The className of container
 * @param dashed - Whether line is dashed
 * @param orientation - The position of title inside divider
 * @param plain - Divider text show as plain style
 * @param style - The style object of container
 * @param type - The direction type of divider
 * @param children - Some ReactNode
 */

const Divider = ({
  className,
  dashed = false,
  orientation = 'center',
  plain = true,
  style,
  type = 'horizontal',
  children,
  ...props
}: IDividerProps) => (
  <AntDivider
    className={className}
    dashed={dashed}
    orientation={orientation}
    plain={plain}
    style={style}
    type={type}
    {...props}
  >
    {children}
  </AntDivider>
);

export default Divider;
