// Packages
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
// API
import { AuthAPI } from 'api';
// Modules
import webStorage from 'modules/storage';
import sessionStorageApp from 'modules/storage/sessionStorage';
// Interfaces and types
import {
  ISignInBodyFirstStep,
  ISignInBodySecondStep,
  ISignInResSecondStep,
  IForgotPassBody,
  TForgotPassRes,
  IRecoverPassBody,
  TRecoverPassRes,
  TRecoverTokenRes,
  ISignInResFirstStep,
} from 'types/authTypes';
import { IErrorRes } from 'types/appTypes';

const signInFirstStepAsync = createAsyncThunk<
  ISignInResFirstStep,
  ISignInBodyFirstStep,
  { rejectValue: IErrorRes }
>('@auth/signInFirstStep', async (cred: ISignInBodyFirstStep, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.signInFirstStep(cred);

    sessionStorageApp.setData({
      accessToken: data?.sessionToken,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const signInSecondStepMFAAsync = createAsyncThunk<
  ISignInResSecondStep,
  ISignInBodySecondStep,
  { rejectValue: IErrorRes }
>(
  '@auth/signISecondStepMFA',
  async (cred: ISignInBodySecondStep, { rejectWithValue }) => {
    try {
      const data = await AuthAPI.signInSecondStepMFA(cred);

      webStorage.setData(data);

      return data;
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const signOutAsync = createAsyncThunk<void, void>('@auth/signOut', () => {
  AuthAPI.signOut();

  webStorage.unsetData();
});

const forgotPasswordAsync = createAsyncThunk<
  TForgotPassRes,
  IForgotPassBody,
  { rejectValue: IErrorRes }
>('@auth/forgotPassword', async (cred: IForgotPassBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.forgotPassword(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const recoverPasswordAsync = createAsyncThunk<
  TRecoverPassRes,
  IRecoverPassBody,
  { rejectValue: IErrorRes }
>('@auth/recoverPassword', async (cred: IRecoverPassBody, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.recoverPassword(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const checkRecoverTokenAsync = createAsyncThunk<
  TRecoverTokenRes,
  string,
  { rejectValue: IErrorRes }
>('@auth/checkRecoverToken', async (cred: string, { rejectWithValue }) => {
  try {
    const data = await AuthAPI.checkRecoverToken(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getCurrentUserAsync = createAsyncThunk<any, void, { rejectValue: IErrorRes }>(
  'auth/getCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const data = await AuthAPI.getCurrentUserDetailsWithPermissions();

      return data;
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export const clearReducer = createAction('auth/clearReducer');

export {
  signInFirstStepAsync,
  signInSecondStepMFAAsync,
  signOutAsync,
  forgotPasswordAsync,
  recoverPasswordAsync,
  checkRecoverTokenAsync,
  getCurrentUserAsync,
};
