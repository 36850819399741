interface IProps {
  primaryColor?: string;
  secondaryColor?: string;
}

/**
 * @param primaryColor - The background logo color. By default '#1B96FF'.
 * @param secondaryColor - The color for the icon inside the logo. By default '#ffffff'.
 */

const User = ({ primaryColor = '#1B96FF', secondaryColor = '#ffffff' }: IProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="3" fill={primaryColor} />
    <path
      d="M16 10.002C14.8965 10.002 14.002 10.8965 14.002 12C14.002 13.1034 14.8965 13.998 16 13.998C17.1034 13.998 17.998 13.1034 17.998 12C17.998 10.8965 17.1034 10.002 16 10.002ZM20.5 10.9999C19.6716 10.9999 19 11.6715 19 12.4999C19 13.3284 19.6716 13.9999 20.5 13.9999C21.3284 13.9999 22 13.3284 22 12.4999C22 11.6715 21.3284 10.9999 20.5 10.9999ZM11.5 10.9999C10.6716 10.9999 10 11.6715 10 12.4999C10 13.3284 10.6716 13.9999 11.5 13.9999C12.3284 13.9999 13 13.3284 13 12.4999C13 11.6715 12.3284 10.9999 11.5 10.9999ZM13 15.9932C13.0035 15.4439 13.4499 14.9996 14 14.9996H18C18.5523 14.9996 19 15.4473 19 15.9996V18.9999C19 19.1035 18.9948 19.2058 18.9845 19.3067C18.9628 19.5208 18.9185 19.7282 18.8542 19.9265C18.4638 21.1299 17.3335 22 16 22C14.6538 22 13.5147 21.1133 13.1349 19.892C13.0472 19.6102 13 19.3106 13 19C13 19 13 19 13 19V15.9996C13 15.9975 13 15.9953 13 15.9932ZM12 15.9996C12 15.6353 12.0974 15.2938 12.2676 14.9996H10C9.44772 14.9996 9 15.4473 9 15.9996V18.5C9 19.8807 10.1193 21 11.5 21C11.8311 21 12.1472 20.9356 12.4364 20.8187C12.1574 20.273 12 19.6549 12 18.9999V15.9996ZM20 15.9996V18.9999C20 19.6549 19.8426 20.273 19.5636 20.8187C19.8528 20.9356 20.1689 21 20.5 21C21.8807 21 23 19.8807 23 18.5V15.9996C23 15.4473 22.5523 14.9996 22 14.9996H19.7324C19.9026 15.2938 20 15.6353 20 15.9996Z"
      fill={secondaryColor}
    />
  </svg>
);

export default User;
