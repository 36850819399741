// Packages
import { createSlice } from '@reduxjs/toolkit';
import {
  getCompaniesAsync,
  signInAsCompanyAsync,
  getCompanyDetails,
  deleteCompanyAsync,
  updateCompanyAsync,
} from 'modules/redux/company/companyThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetCompaniesRes, IGetCompanyDetailsRes } from 'types/companyTypes';

export interface ICompanySliceState {
  company: null | IGetCompanyDetailsRes;
  data: null | IGetCompaniesRes;
  error: null | IErrorRes;
  loading: boolean;
}

const initialState: ICompanySliceState = {
  data: null,
  error: null,
  loading: false,
  company: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    resetCompanyInfo: state => {
      state.company = initialState.company;
    },
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET COMPANIES ASYNC THUNK
    builder.addCase(getCompaniesAsync.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCompaniesAsync.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getCompaniesAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });

    //* GET COMPANY DETAILS ASYNC THUNK
    builder.addCase(getCompanyDetails.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCompanyDetails.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getCompanyDetails.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });

    //* DELETE COMPANY ASYNC THUNK
    builder.addCase(deleteCompanyAsync.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteCompanyAsync.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(deleteCompanyAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });

    //* SIGN IN AS COMPANY ASYNC THUNK
    builder.addCase(signInAsCompanyAsync.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signInAsCompanyAsync.fulfilled, state => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(signInAsCompanyAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });

    //* UPDATE COMPANY PAYMENT INFO ASYNC THUNK
    builder.addCase(updateCompanyAsync.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateCompanyAsync.fulfilled, state => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateCompanyAsync.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
  },
});

export default companySlice;

export const { resetCompanyInfo } = companySlice.actions;
