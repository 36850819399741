// Packages
import { Link } from 'react-router-dom';
// Components
import {
  GridCol,
  GridRow,
  Layout,
  MainContent,
  Image,
  Paragraph,
  Text,
  Divider,
  Button,
} from 'components/lib';
// Redux
import { STORE_KEYS, useAppSelector } from 'modules/redux/store';
// ROUTES
import { ROUTES } from 'router/routes';
// Assets
import notFoundImg from 'assets/images/404.png';
// Types
import { EUserRoles } from 'types/userTypes';
// Styles
import styles from './notFound.module.scss';

const NotFoundPage = () => {
  const { userDetails } = useAppSelector(STORE_KEYS.AUTH);

  const isPartner = userDetails?.role === EUserRoles.PARTNER;
  let redirectPath = ROUTES.COMPANIES;

  if (!userDetails) {
    redirectPath = ROUTES.SIGN_IN;
  }

  if (isPartner) {
    redirectPath = ROUTES.PARTNER_REPORTS;
  }

  return (
    <Layout className={styles.wrapper}>
      <MainContent>
        <GridRow align="middle" justify="center" className={styles.contentWrap}>
          <GridCol span={10} style={{ textAlign: 'center' }}>
            <Image src={notFoundImg} alt="404" className={styles.content} />

            <Paragraph strong className={styles.title}>
              OOPS, SORRY WE CAN NOT FIND THAT PAGE!
            </Paragraph>

            <Text className={styles.text}>
              Either something went wrong or the page does not exist anymore.
            </Text>

            <Divider />

            <Button type="primary">
              <Link to={redirectPath}>GO TO HOMEPAGE</Link>
            </Button>
          </GridCol>
        </GridRow>
      </MainContent>
    </Layout>
  );
};

export default NotFoundPage;
