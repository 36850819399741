// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Moment } from 'moment';
import { DatePicker as AntDatePicker } from 'antd';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { DisabledTimes, OnPanelChange, PanelMode } from 'rc-picker/lib/interface.d';
import { AiOutlineCalendar } from 'react-icons/ai';
// Styles
import styles from './datePicker.module.scss';

export const DATE_FORMAT = 'DD-MMM-YYYY';

interface IDatePickerProps {
  allowClear?: boolean;
  autoFocus?: boolean;
  bordered?: boolean;
  className?: string;
  dataCy?: string;
  dateRender?: (currentDate: Moment, today: Moment) => ReactNode;
  defaultPickerValue?: Moment;
  defaultValue?: Moment;
  disabled?: boolean;
  disabledDate?: (currentDate: Moment) => boolean;
  disabledTime?: (date: Moment | null) => DisabledTimes;
  dropdownClassName?: string;
  format?: string;
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
  inputReadOnly?: boolean;
  locale?: PickerLocale;
  mode?: PanelMode;
  nextIcon?: ReactNode;
  onChange?: (value: Moment | null, dateString: string) => void;
  onOk?: () => void;
  onOpenChange?: (open: boolean) => void;
  onPanelChange?: OnPanelChange<Moment>;
  open?: boolean;
  panelRender?: (originPanel: ReactNode) => ReactNode;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  placeholder?: string;
  popupStyle?: CSSProperties;
  prevIcon?: ReactNode;
  renderExtraFooter?: (mode: PanelMode) => ReactNode;
  showNow?: boolean;
  showToday?: boolean;
  showTime?: boolean | object;
  size?: 'large' | 'middle' | 'small';
  style?: CSSProperties;
  suffixIcon?: ReactNode;
  superNextIcon?: ReactNode;
  superPrevIcon?: ReactNode;
  value?: Moment | null;
}

/**
 * To select or input a date.
 *
 * @description By clicking the input box, you can select a date from a popup calendar.
 *
 * @param allowClear - Whether to show clear button
 * @param autoFocus - If get focus when component mounted
 * @param bordered - Whether has border style
 * @param className - The picker className
 * @param dataCY - Param for testing purpose
 * @param dateRender - Custom rendering function for date cells
 * @param defaultPickerValue - To set default picker date
 * @param defaultValue - To set default date, if start time or end time is null or undefined, the date range will be an open interval
 * @param disabled - Determine whether the DatePicker is disabled
 * @param disabledDate - Specify the date that cannot be selected
 * @param disabledTime - To specify the time that cannot be selected
 * @param dropdownClassName - To customize the className of the popup calendar
 * @param format - To set the date format, refer to moment.js. When an array is provided, all values are used for parsing and first value is used for formatting, support Custom Format
 * @param getPopupContainer - To set the container of the floating layer, while the default is to create a div element in body
 * @param inputReadOnly - Set the readonly attribute of the input tag (avoids virtual keyboard on touch devices)
 * @param locale - Localization configuration
 * @param mode - The picker panel mode( Cannot select year or month anymore? )
 * @param onChange - Callback function, can be executed when the selected time is changing
 * @param onOk - Callback when click ok button
 * @param onOpenChange - Callback function, can be executed whether the popup calendar is popped up or closed
 * @param onPanelChange - Callback when picker panel mode is changed
 * @param open - The open state of picker
 * @param panelRender - Customize panel render
 * @param picker - Set picker type
 * @param placeholder - The placeholder of date input
 * @param popupStyle - To customize the style of the popup calendar
 * @param renderExtraFooter - Render extra footer in panel
 * @param showNow - Whether to show 'Now' button on panel when showTime is set
 * @param showToday - Whether to show Today button
 * @param showTime - To provide an additional time selection
 * @param size - To determine the size of the input box, the height of large and small, are 40px and 24px respectively, while default size is 32px
 * @param style - To customize the style of the input box
 * @param suffixIcon - The custom suffix icon
 * @param value - To set date
 */

const DatePicker = ({
  allowClear = false,
  autoFocus = false,
  bordered = true,
  className,
  dataCy = '',
  dateRender,
  defaultPickerValue,
  defaultValue,
  disabled = false,
  disabledDate,
  disabledTime,
  dropdownClassName,
  format = DATE_FORMAT,
  getPopupContainer,
  inputReadOnly = false,
  locale,
  mode,
  onChange,
  onOk,
  onOpenChange,
  onPanelChange,
  open,
  panelRender,
  picker = 'date',
  placeholder = '',
  popupStyle = {},
  renderExtraFooter,
  showNow,
  showToday = true,
  size = 'middle',
  style,
  showTime = false,
  suffixIcon = <AiOutlineCalendar size={16} />,
  value,
}: IDatePickerProps) => (
  <AntDatePicker
    allowClear={allowClear}
    autoFocus={autoFocus}
    bordered={bordered}
    className={`${styles.datePickerInput} ${className}`}
    data-cy={dataCy}
    dateRender={dateRender}
    defaultPickerValue={defaultPickerValue}
    defaultValue={defaultValue}
    disabled={disabled}
    disabledDate={disabledDate}
    disabledTime={disabledTime}
    dropdownClassName={dropdownClassName}
    format={format}
    getPopupContainer={getPopupContainer}
    inputReadOnly={inputReadOnly}
    locale={locale}
    mode={mode}
    onChange={onChange}
    onOk={onOk}
    onOpenChange={onOpenChange}
    onPanelChange={onPanelChange}
    open={open}
    panelRender={panelRender}
    picker={picker}
    placeholder={placeholder}
    popupStyle={popupStyle}
    renderExtraFooter={renderExtraFooter}
    showNow={showNow}
    showToday={showToday}
    showTime={showTime}
    size={size}
    style={style}
    suffixIcon={suffixIcon}
    value={value}
  />
);

export default DatePicker;
