// Packages
import axios from 'axios';
// Modules
import xhr from 'modules/xhr';
import { apiUrl } from 'configs/appConfig';
// Storage
import webStorage from 'modules/storage';
// Interfaces and types
import { TQueryParams, IMessageRes } from 'types/appTypes';
import {
  IGetPartnersRes,
  ICreatePartnerBody,
  IUpdatePartnerBodyWithParam,
  IGetPartnerDetails,
  ICreatePartnerRes,
} from 'types/partnerTypes';

export const getPartners = (params: TQueryParams) => {
  return xhr.get<IGetPartnersRes, IGetPartnersRes>('/admin/partners', {
    params,
  });
};

export const createPartner = (body: ICreatePartnerBody) => {
  return xhr.post<ICreatePartnerRes, ICreatePartnerRes>('/admin/partners', body);
};

export const updatePartner = ({ id, body }: IUpdatePartnerBodyWithParam) => {
  return xhr.put<IMessageRes, IMessageRes>(`/admin/partners/${id}`, body);
};

export const getPartnerDetails = (id: number) => {
  return xhr.get<IGetPartnerDetails, IGetPartnerDetails>(`/admin/partners/${id}`);
};

export const deletePartner = (id: number) => {
  return xhr.delete<IMessageRes, IMessageRes>(`/admin/partners/${id}`);
};

export const getPartnersListReport = () => {
  const storageData = webStorage.getData();

  return axios.get<Blob>(`${apiUrl}/admin/partner-commissions/export`, {
    headers: {
      Authorization: `Bearer ${storageData.tokens.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': 'en',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};
