// Core
import { Component, ErrorInfo, ReactNode } from 'react';
// Components
import { Button } from 'components/lib';
// Styles
import styles from './errorBoundary.module.scss';

export interface IProps {
  children: ReactNode;
}

export interface IState {
  isReloading: boolean;
  hasUiError: boolean;
}

class ErrorBoundary extends Component<IProps, IState> {
  public state: IState = {
    isReloading: false,
    hasUiError: false,
  };

  public static getDerivedStateFromError(): IState {
    // Update state so the next render will show the fallback UI.

    return { hasUiError: true, isReloading: false };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);

    this.setState({ hasUiError: true });
  }

  handleReloadPage = () => {
    window.location.reload();

    this.setState({ isReloading: true });
  };

  public render() {
    const { children } = this.props;
    const { hasUiError, isReloading } = this.state;

    // Fallback for UI errors
    if (hasUiError) {
      return (
        <div className={styles.wrapper}>
          <h1 className={styles.title}>SORRY, SOMETHING WENT WRONG!</h1>

          <Button
            size="large"
            disabled={isReloading}
            onClick={this.handleReloadPage}
            className={styles.goBack}
          >
            GO TO HOMEPAGE
          </Button>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
