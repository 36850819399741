// Core
import { FC, LazyExoticComponent } from 'react';
// Packages
import { Navigate } from 'react-router-dom';
// Modules
import { useAppSelector, STORE_KEYS } from 'modules/redux/store';
// Router
import { TRoute, ROUTES } from './routes';
import { EUserRoles } from 'types/userTypes';
import storage from 'modules/storage';

type TProps = {
  component: LazyExoticComponent<FC>;
  isRestricted: TRoute['isRestricted'];
};

const PublicRoute = ({ component: Component, isRestricted }: TProps) => {
  const {
    isLoggedIn,
    data: { userId },
  } = useAppSelector(STORE_KEYS.AUTH);

  const isAuth = isLoggedIn && userId;
  const isPartner = storage.getData()?.role === EUserRoles?.PARTNER;
  const navigateRoute = isPartner ? ROUTES.PARTNER_REPORTS : ROUTES.COMPANIES;

  return isAuth && isRestricted ? <Navigate to={navigateRoute} /> : <Component />;
};

export default PublicRoute;
