// Core
import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
// Packages
import { Layout as AntLayout } from 'antd';

interface IContentProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Handling the overall layout of a page.
 *
 * @param className - Container className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Content = ({ className, style, children, ...props }: IContentProps) => (
  <AntLayout.Content className={className} style={style} {...props}>
    {children}
  </AntLayout.Content>
);

export default Content;
