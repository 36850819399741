// Core
import { useState } from 'react';
// Packages
import { BsThreeDots } from 'react-icons/bs';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
// Components
import { Button, Popover, Space } from 'components/lib';
// Custom hooks
import { useBreakpoint } from 'hooks';
// Styles
import styles from './tableSettingButton.module.scss';

interface IProps {
  isDifferentEditItems?: boolean;
  isEditModeVisible?: boolean;
  deleteText?: string;
  editText?: string;
  customActionText?: string;
  canDelete?: boolean;
  canEdit?: boolean;
  onEdit?: (typeOfEditItem?: any) => void;
  onDelete?: () => void;
  onCustomAction?: (param?: any) => void;
}

const TableSettingButton = ({
  isEditModeVisible = true,
  deleteText = '',
  editText = '',
  customActionText = '',
  canDelete = true,
  canEdit = true,
  onDelete,
  onEdit,
  onCustomAction,
}: IProps) => {
  const { md } = useBreakpoint();

  const [isVisible, setIsVisible] = useState(false);
  const handleChangeVisiblePopover = () => setIsVisible(prev => !prev);

  const handleDelete = () => {
    onDelete && onDelete();
    handleChangeVisiblePopover();
  };

  const handleEdit = () => {
    onEdit && onEdit();
    handleChangeVisiblePopover();
  };

  const handleDoCustomAction = () => {
    onCustomAction && onCustomAction();
    handleChangeVisiblePopover();
  };

  const isEditVisible = onEdit && isEditModeVisible;
  const settingsButtonClass = md ? styles.settingsBtn : styles.settingsBtnMobile;

  return (
    <Popover
      zIndex={100}
      trigger="click"
      placement="topRight"
      visible={isVisible}
      onVisibleChange={handleChangeVisiblePopover}
      content={
        <Space direction="vertical" size={0}>
          {canDelete && onDelete && (
            <Button
              block
              type="text"
              disabled={!canDelete}
              icon={<AiOutlineDelete />}
              onClick={handleDelete}
              className={styles.actionBtn}
            >
              {deleteText}
            </Button>
          )}

          {isEditVisible && (
            <Button
              block
              type="text"
              disabled={!canEdit}
              icon={<AiOutlineEdit />}
              onClick={handleEdit}
              className={styles.actionBtn}
            >
              {editText}
            </Button>
          )}

          {onCustomAction && (
            <Button
              block
              type="text"
              disabled={!canEdit}
              icon={<AiOutlineEdit />}
              onClick={handleDoCustomAction}
              className={styles.actionBtn}
            >
              {customActionText}
            </Button>
          )}
        </Space>
      }
    >
      <Button size="small" className={settingsButtonClass}>
        <BsThreeDots size={16} color="#2E3A59" />
      </Button>
    </Popover>
  );
};

export default TableSettingButton;
