// Interfaces and types
import { ISignInResFirstStep } from 'types/authTypes';

export interface IStorageData {
  accessToken?: ISignInResFirstStep['sessionToken'];
}

interface IStorage {
  getData: <T = IStorageData>(key?: string) => T;
  setData: <T = IStorageData>(data: T, key?: string) => void;
  unsetData: (key?: string) => void;
}

const storage: IStorage = {
  getData(key = 'timeGoAdmin') {
    const data = sessionStorage.getItem(key) || '{}';
    return JSON.parse(data);
  },

  setData(data, key = 'timeGoAdmin') {
    sessionStorage.setItem(key, JSON.stringify(data));
  },

  unsetData(key = 'timeGoAdmin') {
    sessionStorage.removeItem(key);
  },
};

export default storage;
