// Core
import { CSSProperties, HTMLAttributes, ReactElement, ReactNode } from 'react';
// Packages
import { Spin as AntSpinner } from 'antd';
// Styles
import styles from './spinner.module.scss';

interface ISpinnerProps extends HTMLAttributes<HTMLElement> {
  delay?: number;
  indicator?: ReactElement<HTMLElement>;
  size?: 'default' | 'small' | 'large';
  spinning?: boolean;
  tip?: ReactNode;
  wrapperClassName?: string;
  className?: string;
  style?: CSSProperties;
}

/**
 * A spinner for displaying loading state of a page or a section.
 *
 * @description When part of the page is waiting for asynchronous data or during a rendering process,
 * an appropriate loading animation can effectively alleviate users' inquietude.
 *
 * @param delay - Specifies a delay in milliseconds for loading state (prevent flush)
 * @param indicator - React node of the spinning indicator
 * @param size - The size of Spin, options: small, default and large
 * @param spinning - Whether Spin is spinning
 * @param tip - Customize description content when Spin has children
 * @param wrapperClassName - he className of wrapper when Spin has children
 * @param className - The className of container
 * @param style - The style object of container
 */

const Spinner = ({
  delay,
  indicator,
  size = 'default',
  spinning = true,
  tip,
  wrapperClassName,
  className,
  style,
  ...props
}: ISpinnerProps) => (
  <AntSpinner
    delay={delay}
    indicator={indicator}
    size={size}
    spinning={spinning}
    tip={tip}
    wrapperClassName={wrapperClassName}
    className={`${styles.spinner} ${className}`}
    style={style}
    {...props}
  />
);

export default Spinner;
