// Packages
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
// Configs
import { apiUrl } from 'configs/appConfig';
// Modules
import webStorage from 'modules/storage';
import sessionStorageApp from 'modules/storage/sessionStorage';
// Constants
import { NOT_FOUND } from 'helpers/constants';
// Helpers
import showNotification from 'helpers/showNotification';
// Interfaces and types
import { ISubscriber, IErrorRes } from 'types/appTypes';
import { ISignInResSecondStep } from 'types/authTypes';

export const checkIfNotFound = (error: IErrorRes | null) => {
  return error?.errorKey ? NOT_FOUND?.includes(error.errorKey) : false;
};

const axiosService = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

//* A boolean indicator that evaluates if a request has already been sent
let isAlreadyFetchingAccessToken = false;

//* Array of original failed requests with a new valid access token
let subscribers: ISubscriber[] = [];

//* On response (error) helper - use to add a new access token for the original requests
const onAccessTokenFetched = (accessToken: string) => {
  subscribers = subscribers.filter(callback => callback(accessToken));
};

//* On response (error) helper - use to add a callback with an original request which was failed
const addSubscriber = (callback: ISubscriber) => {
  subscribers.push(callback);
};

//* On request handler
axiosService.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const { tokens } = webStorage.getData();
    const { accessToken } = sessionStorageApp.getData();

    if (tokens) {
      config!.headers!.Authorization = `Bearer ${tokens?.accessToken}`;
    }

    // two factor auth
    if (!tokens && accessToken) {
      config!.headers!.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    console.error(`[request error] [${JSON.stringify(error)}]`);

    return Promise.reject(error);
  },
);

axiosService.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse['data'] => response.data,
  (error: AxiosError) => {
    const { config, response, message } = error;

    const errorMessage = response?.data.message ?? message;
    const originalRequest = config;

    if (response?.status === 429) {
      message && showNotification('error', errorMessage);

      return Promise.reject({
        message: errorMessage,
        retryTime: error?.response?.headers['retry-after'],
      });
    }

    if (response?.status === 404) {
      showNotification('error', errorMessage as string);
      return Promise.reject({
        ...(response?.data || errorMessage),
        errorKey: errorMessage,
      });
    }

    if (response?.status !== 401 && response?.status !== 400) {
      showNotification('error', errorMessage as string);

      return Promise.reject(error);
    }

    if (response?.status === 400) {
      return Promise.reject(response?.data || errorMessage);
    }

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const storageData = webStorage.getData();

      axios
        .patch<ISignInResSecondStep>(`${apiUrl}/auth/tokens`, {
          refreshToken: storageData?.tokens?.refreshToken,
        })
        .then(({ data }) => {
          isAlreadyFetchingAccessToken = false;

          webStorage.setData(data);

          onAccessTokenFetched(data.tokens.accessToken);
        })
        .catch(error => {
          console.log('Error from interceptor after failing to get refresh token', error);
          showNotification(
            'error',
            'The time of session token is expired - please, Log-in once more',
          );

          setTimeout(() => {
            webStorage.unsetData();
            window.location.reload();
            window.location.replace('/');
          }, 2000);
        });
    }

    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber((accessToken: string) => {
        originalRequest!.headers!.Authorization = 'Bearer ' + accessToken;

        resolve(axiosService(originalRequest));
      });
    });

    return retryOriginalRequest;
  },
);

export default axiosService;
