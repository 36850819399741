// Core
import { FC, lazy, LazyExoticComponent } from 'react';
// Helpers
import { PERMISSION_KEYS } from 'helpers/constants';

/**
  Property definition:
  @property  path - route path
  @property  label - label of navigation 
  @property  exact - exact prop for route
  @property  private - use for defining if the route is private (can be shown only for auth user)
  @property  restricted - use for defining the route which is restricted for auth user (can't be displayed)
  @property  component - lazy component
  @property  isAvailable - defines whether the page can be displayed without authorization
  @property  permissionKey - permission key = defines whether the page should be displayed depending on the user's permissions
 */

export type TRoute = {
  isAvailable: boolean;
  isPrivate: boolean;
  isRestricted: boolean;
  isNavigate: boolean;
  path: string;
  label: string;
  exact: boolean;
  permissionKey: string;
  component: LazyExoticComponent<FC>;
};

export const ROUTES = {
  SIGN_IN: '/',
  FORGOT_PWD: '/forgot-password',
  RECOVER_PWD: '/recover-password',
  CONTACT_US: '/contact-us',
  COMPANIES: '/companies',
  USERS: '/users',
  USER_DETAILS: '/users/:id',
  PARTNERS: '/partners',
  PARTNERS_DETAILS: '/partners/:id',
  PARTNER_REPORTS: '/partner/reports',
  MFA: '/mfa',
  CHANGE_LOG: '/change-log',
};

const routes: TRoute[] = [
  {
    path: ROUTES.SIGN_IN,
    isNavigate: false,
    label: 'Sign In',
    exact: true,
    isPrivate: false,
    isRestricted: true,
    isAvailable: true,
    permissionKey: '',
    component: lazy(
      () => import('pages/SignInPage' /* webpackChunkName: "sign-in-page" */),
    ),
  },
  {
    path: ROUTES.MFA,
    isNavigate: false,
    label: 'MFA',
    exact: true,
    isPrivate: false,
    isRestricted: true,
    isAvailable: true,
    permissionKey: '',
    component: lazy(() => import('pages/MFAPage' /* webpackChunkName: "mfa-page" */)),
  },
  {
    path: ROUTES.FORGOT_PWD,
    isNavigate: false,
    label: 'Forgot Password',
    exact: true,
    isPrivate: false,
    isRestricted: true,
    isAvailable: true,
    permissionKey: '',
    component: lazy(
      () => import('pages/ForgotPassPage' /* webpackChunkName: "forgot-pass-page" */),
    ),
  },
  {
    path: ROUTES.RECOVER_PWD,
    isNavigate: false,
    label: 'Recover Password',
    exact: true,
    isPrivate: false,
    isRestricted: true,
    isAvailable: true,
    permissionKey: '',
    component: lazy(
      () => import('pages/RecoverPassPage' /* webpackChunkName: "recover-pass-page" */),
    ),
  },
  {
    path: ROUTES.PARTNER_REPORTS,
    isNavigate: true,
    label: 'Reports',
    exact: true,
    isPrivate: true,
    isRestricted: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.PARTNER_REPORTS,
    component: lazy(
      () => import('pages/PartnerOwnPage' /* webpackChunkName: "partner-reports" */),
    ),
  },
  {
    path: ROUTES.COMPANIES,
    isNavigate: true,
    label: 'Companies',
    exact: false,
    isPrivate: true,
    isRestricted: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.COMPANIES,
    component: lazy(
      () => import('pages/CompaniesPage' /* webpackChunkName: "companies-page" */),
    ),
  },
  {
    path: ROUTES.USERS,
    isNavigate: true,
    label: 'Users',
    exact: false,
    isPrivate: true,
    isRestricted: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.USERS,
    component: lazy(
      () =>
        import('pages/UsersPage/UsersListPage' /* webpackChunkName: "users-list-page" */),
    ),
  },
  {
    path: ROUTES.USER_DETAILS,
    isNavigate: false,
    label: 'Detail info',
    exact: true,
    isPrivate: true,
    isRestricted: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.USERS,
    component: lazy(
      () =>
        import(
          'pages/UsersPage/UserDetailedPage' /* webpackChunkName: "users-detailed-page" */
        ),
    ),
  },
  {
    path: ROUTES.PARTNERS,
    isNavigate: true,
    label: 'Partners',
    exact: false,
    isPrivate: true,
    isRestricted: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.PARTNERS,
    component: lazy(
      () =>
        import(
          'pages/PartnersPage/PartnersListPage' /* webpackChunkName: "partners--list-page" */
        ),
    ),
  },
  {
    path: ROUTES.PARTNERS_DETAILS,
    isNavigate: false,
    label: 'Partner information',
    exact: true,
    isPrivate: true,
    isRestricted: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.PARTNERS,
    component: lazy(
      () =>
        import(
          'pages/PartnersPage/PartnerDetailedPage' /* webpackChunkName: "partner-detailed-page" */
        ),
    ),
  },
  {
    path: ROUTES.CHANGE_LOG,
    isNavigate: true,
    label: 'Change Log',
    exact: true,
    isPrivate: true,
    isRestricted: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.CHANGE_LOG,
    component: lazy(
      () => import('pages/ChangeLogPage' /* webpackChunkName: "users-list-page" */),
    ),
  },
];

export default routes;
