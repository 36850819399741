// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getUsersAsync,
  createUserAsync,
  getUserDetailsAsync,
  updateUserAsync,
  deleteUserAsync,
} from './userThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetUsersRes, IGetUserDetails } from 'types/userTypes';
import { IErrorRes } from 'types/appTypes';

export interface IUserSliceState {
  users: IGetUsersRes | null;
  userDetails: IGetUserDetails | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IUserSliceState = {
  users: null,
  userDetails: null,
  error: null,
  isLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET USERS ASYNC THUNK
    builder.addCase(getUsersAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getUsersAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users = payload;
    });
    builder.addCase(getUsersAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* CREATE USER (one) ASYNC THUNK
    builder.addCase(createUserAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createUserAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createUserAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET USER DETAILS ASYNC THUNK
    builder.addCase(getUserDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getUserDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.userDetails = payload;
    });
    builder.addCase(getUserDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE USER ASYNC THUNK
    builder.addCase(updateUserAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateUserAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateUserAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* DELETE USER ASYNC THUNK
    builder.addCase(deleteUserAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteUserAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteUserAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default userSlice;
