// Core
import { useState, useEffect, useCallback } from 'react';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';

type TData<TRes> = {
  isLoading: boolean;
  response: TRes | null;
  error: IErrorRes | null;
};
type TDoFetch<TBody = {}> = (data: TBody) => void;
type TDoReset = () => void;
type TReturn<R, B> = [data: TData<R>, doFetch: TDoFetch<B>, doReset: TDoReset];

const useFetch = <TRes, TBody, TFetch>(axios: TFetch): TReturn<TRes, TBody> => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<TRes | null>(null);
  const [error, setError] = useState<IErrorRes | null>(null);
  const [options, setOptions] = useState<TBody | {}>({});

  const doFetch: TDoFetch<TBody> = useCallback(data => {
    setOptions(data);
    setIsLoading(true);
  }, []);

  const doReset: TDoReset = useCallback(() => {
    setResponse(null);
    setIsLoading(false);
    setError(null);
  }, []);

  useEffect(() => {
    if (typeof axios !== 'function') {
      return;
    }

    let skipGetResponseAfterDestroy = false;

    if (!isLoading) {
      return;
    }

    axios(options)
      .then((res: TRes) => {
        if (!skipGetResponseAfterDestroy) {
          setResponse(res);
          setIsLoading(false);
          setError(null);
        }
      })
      .catch((err: IErrorRes) => {
        if (!skipGetResponseAfterDestroy) {
          setError(err);
          setIsLoading(false);
        }
      });

    return () => {
      skipGetResponseAfterDestroy = true;
    };
  }, [isLoading, axios, options]);

  return [{ isLoading, response, error }, doFetch, doReset];
};

export default useFetch;
