// Packages
import queryString, { ParsedQuery, ParseOptions, StringifyOptions } from 'query-string';
import { URLSearchParamsInit } from 'react-router-dom';

// ================ getQueryParams ================
type TGetQueryStr = (query: string, options?: ParseOptions) => ParsedQuery<string>;
const getQueryParams: TGetQueryStr = (
  locationSearch,
  options = { arrayFormat: 'bracket' },
) => queryString.parse(locationSearch, options);

// ================ setQueryString ================
type TSetQueryStr = (object: Record<string, any>, options?: StringifyOptions) => string;
const setQueryString: TSetQueryStr = (locationSearch, options = {}) =>
  queryString.stringify(locationSearch, options);

// ================ getParsedQuery ================
type TGetParsedQuery = (queryParams: { [key: string]: any }) => string;
const getParsedQuery: TGetParsedQuery = queryParams =>
  Object.keys(queryParams)
    .filter(
      key =>
        (queryParams[key] &&
          (queryParams[key].length === undefined || queryParams[key].length > 0)) ||
        queryParams[key] === 0,
    )
    .map(
      key =>
        `${key}=${
          queryParams[key] instanceof Array
            ? queryParams[key].join(`&${key}=`)
            : queryParams[key]
        }`,
    )
    .join('&');

// ================ updatePageUrl ================
type TSearchParams = (
  nextInit: URLSearchParamsInit,
  navigateOptions?: { replace?: boolean | undefined; state?: any } | undefined,
) => void;
type TUpdateUrl = <QP>(
  prevQParams: ParsedQuery<string>,
  newQParams: QP,
  setSearchParams: TSearchParams,
) => void;
const updatePageUrl: TUpdateUrl = (prevQueryParams, newQueryParams, setSearchParams) => {
  const queryParams = {
    ...prevQueryParams,
    ...newQueryParams,
  };

  const query = getParsedQuery(queryParams);

  setSearchParams(query);
};

export { getQueryParams, setQueryString, updatePageUrl };
