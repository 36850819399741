// Packages
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
// Modules
import store from 'modules/redux/store';
// Components
import App from './App';
// Styles
import 'antd/dist/antd.min.css';
import 'styles/main.scss';

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root'),
);
