// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Popover as AntPopover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import type { AlignType } from 'rc-trigger/lib/interface';

interface IPopoverProps {
  align?: AlignType;
  arrowPointAtCenter?: boolean;
  autoAdjustOverflow?: boolean;
  color?: string;
  defaultVisible?: boolean;
  destroyTooltipOnHide?: boolean | { keepParent?: boolean };
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  overlayClassName?: string;
  overlayStyle?: CSSProperties;
  overlayInnerStyle?: CSSProperties;
  placement?: TooltipPlacement;
  trigger?: 'hover' | 'focus' | 'click' | 'contextMenu' | Array<string>;
  visible?: boolean;
  zIndex?: number;
  onVisibleChange?: (visible: boolean) => void;
  content?: ReactNode | (() => ReactNode);
  title?: ReactNode | (() => ReactNode);
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * A simple text popup tip.
 *
 * @description The tip is shown on mouse enter, and is hidden on mouse leave. The Tooltip doesn't support complex text or operations.
 * To provide an explanation of a button/text/operation. It's often used instead of the html title attribute.
 *
 * @param align - This value will be merged into placement's config, please refer to the settings rc-tooltip
 * @param arrowPointAtCenter - Whether the arrow is pointed at the center of target
 * @param autoAdjustOverflow - Whether to adjust popup placement automatically when popup is off screen
 * @param color - The background color
 * @param defaultVisible - Whether the floating tooltip card is visible by default
 * @param destroyTooltipOnHide - Whether destroy tooltip when hidden, parent container of tooltip will be destroyed when keepParent is false
 * @param getPopupContainer - The DOM container of the tip, the default behavior is to create a div element in body
 * @param mouseEnterDelay - Delay in seconds, before tooltip is shown on mouse enter
 * @param mouseLeaveDelay - Delay in seconds, before tooltip is hidden on mouse leave
 * @param overlayClassName - Class name of the tooltip card
 * @param overlayStyle - Style of the tooltip card
 * @param overlayInnerStyle - Style of the tooltip inner content
 * @param placement - The position of the tooltip relative to the target, which can be one of top left right bottom topLeft topRight bottomLeft bottomRight leftTop leftBottom rightTop rightBottom
 * @param trigger - Tooltip trigger mode. Could be multiple by passing an array
 * @param visible - Whether the floating tooltip card is visible or not
 * @param zIndex - Config z-index of Tooltip
 * @param onVisibleChange - Callback executed when visibility of the tooltip card is changed
 * @param title - Title of the card
 * @param className - Can be set to 'goBackBtn' | string
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Popover = ({
  align,
  arrowPointAtCenter,
  autoAdjustOverflow,
  color,
  defaultVisible,
  destroyTooltipOnHide,
  getPopupContainer,
  mouseEnterDelay,
  mouseLeaveDelay,
  overlayClassName,
  overlayStyle,
  overlayInnerStyle,
  placement,
  trigger,
  visible,
  zIndex,
  onVisibleChange,
  title,
  content,
  className,
  style,
  children,
}: IPopoverProps) => (
  <AntPopover
    align={align}
    arrowPointAtCenter={arrowPointAtCenter}
    autoAdjustOverflow={autoAdjustOverflow}
    color={color}
    defaultVisible={defaultVisible}
    destroyTooltipOnHide={destroyTooltipOnHide}
    getPopupContainer={getPopupContainer}
    mouseEnterDelay={mouseEnterDelay}
    mouseLeaveDelay={mouseLeaveDelay}
    overlayClassName={overlayClassName}
    overlayStyle={overlayStyle}
    overlayInnerStyle={overlayInnerStyle}
    placement={placement}
    trigger={trigger}
    visible={visible}
    zIndex={zIndex}
    onVisibleChange={onVisibleChange}
    title={title}
    content={content}
    className={className}
    style={style}
  >
    {children}
  </AntPopover>
);

export default Popover;
