// Core
import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
// Packages
import cx from 'classnames';
import { Statistic } from 'antd';
// Styles
import styles from './countDown.module.scss';

const { Countdown } = Statistic;

type HTMLCountProps = Omit<
  HTMLAttributes<HTMLElement>,
  'title' | 'prefix' | 'suffix' | 'onChange'
>;

interface ICountDown extends HTMLCountProps {
  format?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  title?: ReactNode;
  value?: number;
  valueStyle?: CSSProperties;
  className?: string;
  onFinish?: () => void;
  onChange?: (value?: number | string) => void;
}

/**
 * @param format - Format as moment
 * @param prefix - The prefix node of value
 * @param suffix - The suffix node of value
 * @param title - Display title
 * @param value - Set target countdown time
 * @param valueStyle - 	Set value css style
 * @param className - 	Component className
 * @param onFinish - Trigger when time's up
 * @param onChange - Trigger when time's changing
 */

const CountDown = ({
  format,
  prefix,
  suffix,
  title,
  value,
  valueStyle,
  className,
  onFinish,
  onChange,
  ...props
}: ICountDown) => (
  <Countdown
    format={format}
    prefix={prefix}
    suffix={suffix}
    title={title}
    value={value}
    className={cx(styles.countDown, className)}
    valueStyle={valueStyle}
    onFinish={onFinish}
    onChange={onChange}
    {...props}
  />
);

export default CountDown;
