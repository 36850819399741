// Packages
import { Link } from 'react-router-dom';
// Components
import { GridRow, GridCol, MainFooter, Paragraph } from 'components/lib';
// Assets
import { Dot } from 'assets/icons';
// Routes
import { ROUTES } from 'router/routes';
// Styles
import styles from './footer.module.scss';

const Footer = () => (
  <MainFooter data-testid="footer" className={styles.footer}>
    <GridRow justify="center" align="middle">
      <GridCol>
        <div className={styles.support}>
          <Dot />

          <Paragraph className={styles.linkWrap}>
            <Link to={ROUTES.CONTACT_US} target="_blank">
              Contact Us
            </Link>
          </Paragraph>

          <Dot />
        </div>
      </GridCol>
    </GridRow>
  </MainFooter>
);

export default Footer;
