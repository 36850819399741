// Packages
import axios from 'axios';
// Modules
import xhr from 'modules/xhr';
// Storage
import webStorage from 'modules/storage';
// Configs
import { apiUrl } from 'configs/appConfig';
// Interfaces and types
import {
  IForgotPassBody,
  TForgotPassRes,
  IRecoverPassBody,
  TRecoverPassRes,
  TRecoverTokenRes,
  ICurrentUserInfo,
  ISignInBodyFirstStep,
  ISignInResFirstStep,
  ISignInResSecondStep,
  ISignInBodySecondStep,
  IResendCodeRes,
} from 'types/authTypes';

export const signInFirstStep = (body: ISignInBodyFirstStep) => {
  return xhr.post<ISignInResFirstStep, ISignInResFirstStep>('/auth/admin', body);
};

export const signInSecondStepMFA = (body: ISignInBodySecondStep) => {
  return xhr.post<ISignInResSecondStep, ISignInResSecondStep>(
    '/two-factor-challenge/admin',
    body,
  );
};

export const resendTwoFactorCode = () => {
  return xhr.post<IResendCodeRes, IResendCodeRes>(`/two-factor-code`);
};

export const signOut = () => {
  delete xhr.defaults.headers.common.Authorization;
};

export const forgotPassword = (body: IForgotPassBody) => {
  return xhr.post<TForgotPassRes, TForgotPassRes>('/auth/forgot-password/admin', body);
};

export const recoverPassword = (body: IRecoverPassBody) => {
  return xhr.post<TRecoverPassRes, TRecoverPassRes>('/auth/recover-password', body);
};

export const checkRecoverToken = (token: string) => {
  return xhr.get<TRecoverTokenRes, TRecoverTokenRes>(
    `/auth/verify-password-recovery-token/${token}`,
  );
};

export const getCurrentUserDetailsWithPermissions = () => {
  return xhr.get<ICurrentUserInfo, ICurrentUserInfo>(
    `/admin/users/me?include[]=adminPlatformPermissions`,
  );
};

export const refreshToken = () => {
  const storageData = webStorage.getData();

  return axios.patch<ISignInResSecondStep>(`${apiUrl}/auth/tokens`, {
    refreshToken: storageData?.tokens.refreshToken,
    headers: {
      Authorization: `Bearer ${storageData.tokens.accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};
