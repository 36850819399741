// Core
import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
// Packages
import { Layout as AntLayout } from 'antd';

interface ILayoutProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  hasSider?: boolean;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Handling the overall layout of a page.
 *
 * @param className - Container className
 * @param hasSider - Whether contain Sider in children, don't have to assign it normally. Useful in ssr avoid style flickering
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Layout = ({ className, hasSider, style, children, ...props }: ILayoutProps) => (
  <AntLayout className={className} hasSider={hasSider} style={style} {...props}>
    {children}
  </AntLayout>
);

export default Layout;
