// Components
import { Space, FormItem, Button } from 'components/lib';
// Styles
import styles from './modalFooter.module.scss';

interface IProps {
  formName: string;
  submitType: 'Create' | 'Update';
  isLoading?: boolean;
  onCancel: () => void;
  onDelete?: () => void;
}

const ModalFooter = ({ formName, submitType, isLoading, onCancel, onDelete }: IProps) => (
  <Space className={styles.footerWrap}>
    <FormItem className={styles.formItem}>
      <Button key="back" size="large" onClick={onCancel} className={styles.footerBtn}>
        Cancel
      </Button>
    </FormItem>

    {submitType === 'Update' && !!onDelete && (
      <FormItem className={styles.formItem}>
        <Button
          danger
          key="delete"
          size="large"
          onClick={onDelete}
          className={styles.footerBtn}
        >
          Delete
        </Button>
      </FormItem>
    )}

    <FormItem className={styles.formItem}>
      <Button
        key="submit"
        size="large"
        type="primary"
        form={formName}
        htmlType="submit"
        loading={isLoading}
        className={styles.footerBtn}
      >
        {submitType === 'Create' ? 'Add' : 'Save'}
      </Button>
    </FormItem>
  </Space>
);

export default ModalFooter;
