import { Grid } from 'antd';

const { useBreakpoint } = Grid;
/**
 * Breakpoint = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'
 */
export { useBreakpoint };

export { default as useTable } from './useTable';
export {
  useUserPermissions,
  useRoutePermissions,
  useUserPermissionsDetails,
} from './usePermissions';
export { default as useClearCacheAndReloadAppAfterNewBuild } from './useClearCacheAndReloadAppAfterNewBuild';
export { default as useFetch } from './useFetch';
