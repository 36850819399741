// Core
import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
// Packages
import { Row as AntRow } from 'antd';
import { Gutter } from 'antd/lib/grid/row';

interface IRowProps extends HTMLAttributes<HTMLDivElement> {
  align?: 'top' | 'middle' | 'bottom';
  gutter?: Gutter | [Gutter, Gutter];
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  wrap?: boolean;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * 24 Grids System.
 *
 * @param align - Vertical alignment
 * @param gutter - Spacing between grids, could be a number or a object like { xs: 8, sm: 16, md: 24}.
 * Or you can use array to make horizontal and vertical spacing work at the same time [horizontal, vertical]
 * @param justify -	Horizontal arrangement
 * @param wrap -	Auto wrap line
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Row = ({
  align = 'top',
  gutter = 0,
  justify = 'start',
  wrap = true,
  className,
  style,
  children,
  ...props
}: IRowProps) => (
  <AntRow
    align={align}
    gutter={gutter}
    justify={justify}
    wrap={wrap}
    className={className}
    style={style}
    {...props}
  >
    {children}
  </AntRow>
);

export default Row;
