// Packages
import { FiLogOut } from 'react-icons/fi';
// Components
import { Button } from 'components/lib';
// Modules
import { signOutAsync } from 'modules/redux/auth/authThunk';
import { useAppDispatch } from 'modules/redux/store';
// Styles
import styles from './authMenu.module.scss';

const AuthMenu = () => {
  const dispatch = useAppDispatch();

  const handleSignOut = () => dispatch(signOutAsync());

  return (
    <Button
      icon={<FiLogOut size={20} color="#2e3a59" data-testid="logOutIcon" />}
      onClick={handleSignOut}
      className={styles.authBtn}
    >
      Log Out
    </Button>
  );
};

export default AuthMenu;
