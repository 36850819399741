// Core
import { ReactNode, CSSProperties } from 'react';
// Packages
import { Radio as AntRadio, RadioChangeEvent } from 'antd';

export type RChangeEvt = RadioChangeEvent;

type TRadioValueType = string | number | boolean;

type TOption = {
  label: ReactNode;
  value: TRadioValueType;
  style?: CSSProperties;
  disabled?: boolean;
};

interface IRadioProps {
  buttonStyle?: 'outline' | 'solid';
  defaultValue?: TRadioValueType;
  disabled?: boolean;
  name?: string;
  options?: string[] | TOption[];
  optionType?: 'default' | 'button';
  size?: 'large' | 'middle' | 'small';
  value?: TRadioValueType;
  onChange?: (e: RadioChangeEvent) => void;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Radio Group.
 *
 * @description Used to select a single state from multiple options.
 * The difference from Select is that Radio is visible to the user and
 * can facilitate the comparison of choice, which means there shouldn't be too many of them.
 *
 * @param buttonStyle - The style type of radio button
 * @param defaultValue - Default selected value
 * @param disabled - 	Disable all radio buttons
 * @param name - The name property of all input[type="radio"] children
 * @param options - Set children optional
 * @param optionType - Set Radio optionType
 * @param size - The size of radio button style
 * @param value - Used for setting the currently selected value
 * @param onChange - The callback function that is triggered when the state changes
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const RadioGroup = ({
  buttonStyle = 'outline',
  defaultValue,
  disabled = false,
  name,
  options = [],
  optionType = 'default',
  size = 'middle',
  value,
  onChange,
  className,
  style,
  children,
}: IRadioProps) => (
  <AntRadio.Group
    buttonStyle={buttonStyle}
    defaultValue={defaultValue}
    disabled={disabled}
    name={name}
    options={options}
    optionType={optionType}
    size={size}
    value={value}
    onChange={onChange}
    className={className}
    style={style}
  >
    {children}
  </AntRadio.Group>
);

export default RadioGroup;
