// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { UserApi } from 'api';
// Interfaces and types
import {
  IGetUsersRes,
  ICreateUserBody,
  IGetUserDetails,
  ICreateUserBodyWithParam,
} from 'types/userTypes';
import { TQueryParams, IErrorRes, IMessageRes } from 'types/appTypes';

const getUsersAsync = createAsyncThunk<
  IGetUsersRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('user/getUsersList', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await UserApi.getUsers(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createUserAsync = createAsyncThunk<
  IMessageRes,
  ICreateUserBody,
  { rejectValue: IErrorRes }
>('user/createUser', async (cred: ICreateUserBody, { rejectWithValue }) => {
  try {
    return await UserApi.createUser(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getUserDetailsAsync = createAsyncThunk<
  IGetUserDetails,
  number,
  { rejectValue: IErrorRes }
>('user/getUserDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await UserApi.getUserDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateUserAsync = createAsyncThunk<
  IMessageRes,
  ICreateUserBodyWithParam,
  { rejectValue: IErrorRes }
>('user/updateUser', async (cred: ICreateUserBodyWithParam, { rejectWithValue }) => {
  try {
    return await UserApi.updateUser(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteUserAsync = createAsyncThunk<IMessageRes, number, { rejectValue: IErrorRes }>(
  'user/deleteUser',
  async (id: number, { rejectWithValue }) => {
    try {
      return await UserApi.deleteUser(id);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getUsersAsync,
  createUserAsync,
  getUserDetailsAsync,
  updateUserAsync,
  deleteUserAsync,
};
