// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getPartnersAsync,
  createPartnerAsync,
  getPartnerDetailsAsync,
  updatePartnerAsync,
  deletePartnerAsync,
} from './partnerThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetPartnerDetails, IGetPartnersRes } from 'types/partnerTypes';
import { IErrorRes } from 'types/appTypes';

export interface IPartnerSliceState {
  partners: IGetPartnersRes | null;
  partnerDetails: IGetPartnerDetails | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IPartnerSliceState = {
  partners: null,
  partnerDetails: null,
  error: null,
  isLoading: false,
};

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET PARTNERS ASYNC THUNK
    builder.addCase(getPartnersAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPartnersAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.partners = payload;
    });
    builder.addCase(getPartnersAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE PARTNER (one) ASYNC THUNK
    builder.addCase(createPartnerAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createPartnerAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createPartnerAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET PARTNER DETAILS ASYNC THUNK
    builder.addCase(getPartnerDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPartnerDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.partnerDetails = payload;
    });
    builder.addCase(getPartnerDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE USER ASYNC THUNK
    builder.addCase(updatePartnerAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updatePartnerAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updatePartnerAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE PARTNER ASYNC THUNK
    builder.addCase(deletePartnerAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deletePartnerAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deletePartnerAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default partnerSlice;
