// Packages
import { notification } from 'antd';
import { IconType, NotificationPlacement } from 'antd/lib/notification';

type TNotification = (
  type: IconType,
  message: string,
  description?: string,
  placement?: NotificationPlacement,
  duration?: number,
) => void;

const showNotification: TNotification = (
  type,
  message,
  description = '',
  placement = 'topRight',
  duration = 5,
) => {
  notification[type as IconType]({ message, description, placement, duration });
};

export default showNotification;
