export enum EUserRoles {
  ADMIN = 'admin',
  SUPPORT = 'support',
  PARTNER = 'partner',
}

export enum EModal {
  CREATE = 'create',
  UPDATE = 'update',
}

interface IUser {
  userId: number;
  firstName: 'string';
  lastName: 'string';
  role: EUserRoles;
  email: 'string';
}

//* ENDPOINT "/admin/users" METHOD "GET" */
export interface IGetUserItem {
  userId: IUser['userId'];
  firstName: IUser['firstName'];
  lastName: IUser['lastName'];
  role: IUser['role'];
}

export interface IGetUsersRes {
  count: number;
  items: IGetUserItem[];
}

//* ENDPOINT "/admin/users" METHOD "POST" */
export interface ICreateUserBody {
  firstName: IUser['firstName'];
  lastName: IUser['lastName'];
  role: IUser['role'];
  email: IUser['email'];
}

//* ENDPOINT "/admin/users/id" METHOD "GET" */
export interface IGetUserDetails {
  firstName: IUser['firstName'];
  lastName: IUser['lastName'];
  role: IUser['role'];
  email: IUser['email'];
  userId: IUser['userId'];
}

//* ENDPOINT "/admin/users/id" METHOD "PUT" */
export interface ICreateUserBodyWithParam {
  id: number;
  body: ICreateUserBody;
}
