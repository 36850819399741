// Core
import { useState } from 'react';
// Packages
import { NavLink, useLocation } from 'react-router-dom';
// Components
import { Menu } from 'components/lib';
import { TMenuInfo } from 'components/lib/Menu';
// CustomHooks
import { useRoutePermissions } from 'hooks/usePermissions';
// Routes
import routes from 'router/routes';
// Styles
import styles from './navigation.module.scss';

interface IProps {
  mode?: 'horizontal' | 'inline' | 'vertical';
  onToggleMenu?: () => void;
}

const Navigation = ({ mode = 'horizontal', onToggleMenu }: IProps) => {
  const { pathname } = useLocation();
  const [currentKey, setCurrentKey] = useState<string>(pathname);

  const handleClick = ({ key }: TMenuInfo) => {
    setCurrentKey(key as string);

    onToggleMenu && onToggleMenu();
  };
  const allowedRoutes = useRoutePermissions(routes);

  const nav = allowedRoutes
    .filter(({ isNavigate }) => isNavigate)
    .map(({ path, label }) => {
      return (
        <Menu.Item key={path} className={styles.navMenuItem}>
          <NavLink
            className={({ isActive }) => (isActive ? styles.activeLink : '')}
            to={path}
          >
            {label}
          </NavLink>
        </Menu.Item>
      );
    });

  return (
    <Menu
      theme="light"
      mode={mode}
      selectedKeys={['horizontal']}
      defaultSelectedKeys={[currentKey]}
      onClick={handleClick}
      className={styles.navMenu}
    >
      {nav}
    </Menu>
  );
};

export default Navigation;
