// Core
import { FC, LazyExoticComponent } from 'react';
// Packages
import { Navigate } from 'react-router-dom';
// Modules
import { useAppSelector, STORE_KEYS } from 'modules/redux/store';
// Router
import { ROUTES } from './routes';

type TProps = {
  component: LazyExoticComponent<FC>;
};

const PrivateRoute = ({ component: Component }: TProps) => {
  const {
    isLoggedIn,
    data: { userId },
  } = useAppSelector(STORE_KEYS.AUTH);

  const isAuth = isLoggedIn && userId;

  return isAuth ? <Component /> : <Navigate to={ROUTES.SIGN_IN} />;
};

export default PrivateRoute;
