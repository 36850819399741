// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { PartnerApi } from 'api';
// Interfaces and types
import {
  IGetPartnersRes,
  ICreatePartnerBody,
  IUpdatePartnerBodyWithParam,
  IGetPartnerDetails,
  ICreatePartnerRes,
} from 'types/partnerTypes';
import { TQueryParams, IErrorRes, IMessageRes } from 'types/appTypes';

const getPartnersAsync = createAsyncThunk<
  IGetPartnersRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('partners/getPartnersList', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await PartnerApi.getPartners(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createPartnerAsync = createAsyncThunk<
  ICreatePartnerRes,
  ICreatePartnerBody,
  { rejectValue: IErrorRes }
>('partners/createPartner', async (cred: ICreatePartnerBody, { rejectWithValue }) => {
  try {
    return await PartnerApi.createPartner(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getPartnerDetailsAsync = createAsyncThunk<
  IGetPartnerDetails,
  number,
  { rejectValue: IErrorRes }
>('partners/getPartnerDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await PartnerApi.getPartnerDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updatePartnerAsync = createAsyncThunk<
  IMessageRes,
  IUpdatePartnerBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'partners/updatePartner',
  async (cred: IUpdatePartnerBodyWithParam, { rejectWithValue }) => {
    try {
      return await PartnerApi.updatePartner(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deletePartnerAsync = createAsyncThunk<
  IMessageRes,
  number,
  { rejectValue: IErrorRes }
>('partners/deletePartner', async (id: number, { rejectWithValue }) => {
  try {
    return await PartnerApi.deletePartner(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getPartnersAsync,
  createPartnerAsync,
  getPartnerDetailsAsync,
  updatePartnerAsync,
  deletePartnerAsync,
};
