// Core
import { ReactNode } from 'react';
// Packages
import { AiOutlineLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
// Components
import {
  Avatar,
  GridCol,
  GridRow,
  MainHeader,
  Space,
  Title,
  Button,
} from 'components/lib';
// Styles
import styles from './pageHeader.module.scss';

interface IProps {
  hasGoBack?: boolean;
  goBackUrl?: string;
  title: string;
  icon?: ReactNode;
  state?: any;
  children?: ReactNode;
}

const PageHeader = ({
  hasGoBack = false,
  goBackUrl,
  icon,
  title,
  state,
  children,
}: IProps) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    if (!goBackUrl) {
      return;
    }

    const searchParams = state?.from?.search ?? '';
    navigate(`${goBackUrl}${searchParams}`);
  };

  return (
    <MainHeader data-testid="pageHeader" className={styles.pageHeader}>
      <GridRow align="middle" justify="start" className={styles.menuContainer}>
        <GridCol>
          <Space size="middle" align="center">
            {hasGoBack && <Button icon={<AiOutlineLeft />} onClick={handleGoBack} />}
            {icon && <Avatar src={icon} shape="square" />}

            <Title className={styles.title} ellipsis={{ tooltip: title }} level={4}>
              {title}
            </Title>
          </Space>
        </GridCol>
      </GridRow>

      {children && (
        <GridRow>
          <GridCol span={24}>{children}</GridCol>
        </GridRow>
      )}
    </MainHeader>
  );
};

export default PageHeader;
