// Modules
import xhr from 'modules/xhr';
// Interfaces and types
import { TQueryParams, IMessageRes } from 'types/appTypes';
import {
  IGetUsersRes,
  ICreateUserBody,
  IGetUserDetails,
  ICreateUserBodyWithParam,
} from 'types/userTypes';

export const getUsers = (params: TQueryParams) => {
  return xhr.get<IGetUsersRes, IGetUsersRes>('/admin/users', {
    params,
  });
};

export const createUser = (body: ICreateUserBody) => {
  return xhr.post<IMessageRes, IMessageRes>('/admin/users', body);
};

export const updateUser = ({ id, body }: ICreateUserBodyWithParam) => {
  return xhr.put<IMessageRes, IMessageRes>(`/admin/users/${id}`, body);
};

export const getUserDetails = (id: number) => {
  return xhr.get<IGetUserDetails, IGetUserDetails>(`/admin/users/${id}`);
};

export const deleteUser = (id: number) => {
  return xhr.delete<IMessageRes, IMessageRes>(`/admin/users/${id}`);
};
