// Core
import { ReactNode, CSSProperties, MouseEvent } from 'react';
// Packages
import { ButtonProps } from 'antd';
import { LegacyButtonType } from 'antd/lib/button/button';
// Components
import { Modal } from 'components/lib';
// Styles
import styles from './actionModal.module.scss';

interface IProps {
  afterClose?: () => void;
  bodyStyle?: CSSProperties;
  cancelButtonProps?: ButtonProps;
  cancelText?: ReactNode;
  centered?: boolean;
  children?: ReactNode;
  className?: string;
  closable?: boolean;
  closeIcon?: ReactNode;
  confirmLoading?: boolean;
  destroyOnClose?: boolean;
  focusTriggerAfterClose?: boolean;
  footer?: ReactNode;
  forceRender?: boolean;
  getContainer?: string | HTMLElement | (() => HTMLElement) | false;
  keyboard?: boolean;
  mask?: boolean;
  maskClosable?: boolean;
  maskStyle?: CSSProperties;
  modalRender?: (node: ReactNode) => ReactNode;
  okButtonProps?: ButtonProps;
  okText?: ReactNode;
  okType?: LegacyButtonType;
  style?: CSSProperties;
  title?: ReactNode;
  visible?: boolean;
  width?: string | number;
  wrapClassName?: string;
  zIndex?: number;
  onCancel?: (e: MouseEvent<HTMLElement>) => void;
  onOk?: (e: MouseEvent<HTMLElement>) => void;
}

const ActionModal = ({
  afterClose,
  bodyStyle,
  cancelButtonProps,
  cancelText = 'Cancel',
  centered = false,
  children,
  className,
  closable = true,
  closeIcon,
  confirmLoading = false,
  destroyOnClose = true,
  focusTriggerAfterClose = true,
  footer,
  forceRender = false,
  getContainer,
  keyboard = true,
  mask = true,
  maskClosable = true,
  maskStyle,
  modalRender,
  okButtonProps,
  okText = 'Ok',
  okType = 'primary',
  style,
  title,
  visible = false,
  width = '100%',
  wrapClassName,
  zIndex = 1000,
  onCancel,
  onOk,
}: IProps) => (
  <Modal
    width={width}
    title={title}
    visible={visible}
    onCancel={onCancel}
    afterClose={afterClose}
    bodyStyle={bodyStyle}
    cancelButtonProps={cancelButtonProps}
    cancelText={cancelText}
    centered={centered}
    closable={closable}
    closeIcon={closeIcon}
    confirmLoading={confirmLoading}
    destroyOnClose={destroyOnClose}
    focusTriggerAfterClose={focusTriggerAfterClose}
    footer={footer}
    forceRender={forceRender}
    getContainer={getContainer}
    keyboard={keyboard}
    mask={mask}
    maskClosable={maskClosable}
    maskStyle={maskStyle}
    modalRender={modalRender}
    okButtonProps={okButtonProps}
    okText={okText}
    okType={okType}
    style={style}
    wrapClassName={wrapClassName}
    zIndex={zIndex}
    onOk={onOk}
    className={`${styles.modal} ${className}`}
  >
    {children}
  </Modal>
);

ActionModal.info = Modal.info;
ActionModal.success = Modal.success;
ActionModal.error = Modal.error;
ActionModal.warning = Modal.warning;
ActionModal.confirm = Modal.confirm;

export default ActionModal;
